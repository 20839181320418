.template-table .rt-tr {
  align-items: center; }

.template-table .template-link {
  padding-left: .5rem;
  padding-right: .5rem;
  margin-right: .3rem;
  border-radius: 3px;
  background-color: rgba(61, 95, 192, 0.08);
  border: 1px solid rgba(61, 95, 192, 0.1); }
  .template-table .template-link:hover {
    background-color: rgba(61, 95, 192, 0.2); }

.mini-inline-stat {
  font-size: 1rem; }
  .mini-inline-stat .icon {
    vertical-align: text-top;
    margin-right: 7px;
    width: 16px;
    height: 16px; }

.inline-stat-group .mini-inline-stat {
  margin-left: 2rem; }

.nav-tabs .nav-link:hover {
  cursor: pointer; }

.nav-tabs .nav-link.active, .nav-tabs .nav-link.active:hover {
  font-weight: 600;
  color: #3D5FC0; }

.report-summary {
  min-width: 41rem;
  text-align: left; }

.report-summary-metrics {
  display: flex;
  justify-content: space-between; }
  .report-summary-metrics .summary-metric {
    margin: 2rem; }
    .report-summary-metrics .summary-metric .title {
      display: flex;
      align-items: center;
      gap: .25rem;
      font-size: .9rem;
      color: #6F7585;
      line-height: 1;
      margin-bottom: .3rem; }
    .report-summary-metrics .summary-metric .value {
      font-size: 1.1rem;
      font-weight: 600; }

.report-summary-stat .card {
  padding: 1rem;
  border-bottom: 0;
  box-shadow: none;
  text-align: center; }
  .report-summary-stat .card:first-child {
    border-left: 0; }
  .report-summary-stat .card:last-child {
    border-right: 0; }

.report-summary-stat .title {
  display: block;
  font-size: .75rem;
  font-weight: 600;
  white-space: nowrap; }

.report-summary-stat .value {
  font-size: 1.1rem;
  font-weight: 600; }

.repricing-insight-popover {
  min-width: 1080px; }

.repricing-insight {
  font-size: 1rem; }
  .repricing-insight .content-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem; }
  .repricing-insight .table > tbody > tr:first-child > td {
    border-top: 0; }
  .repricing-insight .repricing-result {
    margin-bottom: 2rem; }
    .repricing-insight .repricing-result .price {
      font-size: 2rem;
      font-weight: 700; }
    .repricing-insight .repricing-result .icon-arrow {
      position: relative;
      top: -.2rem;
      margin: 0 .5rem; }
    .repricing-insight .repricing-result .sku {
      white-space: nowrap; }
    .repricing-insight .repricing-result .loading {
      font-size: 1.2rem;
      font-weight: normal; }
      .repricing-insight .repricing-result .loading .loading-icon {
        margin-right: .3rem; }

.report-rejection-stat .rejection-stat-item {
  margin-bottom: 1.5rem; }

.rejection-stat-item {
  display: flex;
  align-items: flex-start; }
  .rejection-stat-item .rejection-bar {
    width: 100%;
    margin: 0 1rem; }
  .rejection-stat-item .progress-bar {
    height: 2rem; }
  .rejection-stat-item .value {
    flex: 1 0 auto;
    font-size: .9rem; }
    .rejection-stat-item .value span {
      display: block; }
  .rejection-stat-item svg {
    top: .3rem;
    position: relative; }
  .rejection-stat-item.ok svg {
    color: #00c854; }
  .rejection-stat-item.ok .progress-bar {
    background-color: #00c854; }
  .rejection-stat-item.warning svg {
    color: #f1bf33; }
  .rejection-stat-item.warning .progress-bar {
    background-color: #f1bf33; }
  .rejection-stat-item.danger svg {
    color: #f15f33; }
  .rejection-stat-item.danger .progress-bar {
    background-color: #f15f33; }

@media screen and (max-width: 1437px) {
  .reprice-report-details .simple-metric-item .title {
    width: 6rem;
    overflow-wrap: break-word; } }
