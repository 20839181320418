.profitability-metrics {
  display: flex;
  justify-content: space-evenly;
  padding: 3rem 0;
  background: rgba(99, 108, 114, 0.02); }
  .profitability-metrics .title {
    margin-bottom: .3rem;
    color: #636c72; }
  .profitability-metrics .value {
    font-size: 1.8rem;
    color: #3D5FC0; }
