.table-employee-tracking {
  table-layout: auto; }
  .table-employee-tracking th, .table-employee-tracking td {
    text-align: center; }
    .table-employee-tracking th:first-child, .table-employee-tracking td:first-child {
      text-align: left; }
  .table-employee-tracking tbody tr {
    cursor: pointer; }
    .table-employee-tracking tbody tr.expanded .icon-arrow-right {
      transform: rotate(90deg); }
  .table-employee-tracking .reset-expansion-style,
  .table-employee-tracking .row-expansion-style {
    padding: 0 !important; }
  .table-employee-tracking .table-entries {
    margin-bottom: 0; }
    .table-employee-tracking .table-entries tbody {
      background: #fafafa; }
    .table-employee-tracking .table-entries tr {
      cursor: auto; }
    .table-employee-tracking .table-entries tr > td {
      padding-top: .3rem;
      padding-bottom: .3rem;
      border: 0;
      border-bottom: 1px solid #eeeeee; }
    .table-employee-tracking .table-entries tr:last-child > td {
      border-bottom: 0; }
