.rich-message-box {
  padding: 2rem;
  position: relative;
  border-radius: 5px;
  margin-bottom: 3rem;
  background-image: url("../../../assets/images/shape-dark-h.svg");
  background-repeat: no-repeat;
  background-size: cover; }
  .rich-message-box .carousel-control-prev,
  .rich-message-box .carousel-control-next {
    width: auto; }
  .rich-message-box .btn-close {
    position: absolute;
    top: .3rem;
    right: .5rem;
    font-size: 1.2rem;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.8);
    border: 0; }
  .rich-message-box .btn-secondary {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.6);
    border-color: rgba(255, 255, 255, 0.6); }
  .rich-message-box .inner {
    display: flex;
    justify-content: center;
    width: 90rem;
    margin: 0 auto;
    color: #fff; }
  .rich-message-box .message-media {
    width: 630px;
    height: 236px;
    overflow: hidden;
    border-radius: 3px; }
    .rich-message-box .message-media img {
      border-radius: 3px; }
  .rich-message-box .content {
    flex: 0 0 50%;
    padding-left: 2rem; }
  .rich-message-box .date {
    color: rgba(255, 255, 255, 0.5); }
  .rich-message-box .title {
    margin-bottom: 1.1rem; }
  .rich-message-box .controls {
    margin-top: 1.5rem; }
    .rich-message-box .controls .btn {
      margin-right: 1rem; }

.modal-message-preview {
  max-width: 1400px; }
  .modal-message-preview .rich-message-box {
    margin-bottom: 0; }
    .modal-message-preview .rich-message-box .inner {
      width: auto; }
