.btn-link {
  box-shadow: none;
  padding: 0;
  font-weight: normal;
  cursor: pointer; }
  .btn-link .icon {
    vertical-align: text-top; }
  .btn-link.btn-success {
    color: #00943E; }
  .btn-link.btn-danger {
    color: #FF7158; }
  .btn-link:hover span {
    text-decoration: underline; }

.btn-link-group button {
  margin-right: 20px;
  line-height: 1; }

.animate-spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear; }

.animate-reverse {
  -webkit-animation-direction: reverse;
  animation-direction: reverse; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.badge {
  font-size: 12px;
  font-weight: 600; }

.badge-deleted {
  border: 1px solid #FF7158;
  color: #FF7158; }

.badge-completed {
  border: 1px solid #00943E;
  color: #00943E; }

.badge-inprogress {
  border: 1px solid #FCB036;
  color: #FCB036; }

.btn-secondary {
  color: #858C82;
  border-color: #E7E7F0;
  background-color: #fff;
  box-shadow: none; }
  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
    background-color: #fafafa;
    border-color: #E7E7F0;
    color: #858C82; }
  .btn-secondary.active {
    background-color: #3D5FC0;
    border-color: #3D5FC0;
    color: #fff; }

.show > .btn-secondary.dropdown-toggle {
  background-color: #fafafa;
  color: #858C82; }

.error-message {
  margin: 0;
  font-size: 12px;
  color: #FF7158; }

.card-section {
  margin: 50px 0; }

.card-section-header {
  margin-bottom: 35px; }

.card-section-title {
  display: flex;
  font-size: 20px;
  font-weight: 600;
  color: #00943E; }
  .card-section-title .icon {
    margin-right: 8px; }

.card-section-description {
  color: #858C82; }

.hide-first {
  display: none; }

.popover {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2); }

.popover-title {
  background: #fff;
  padding: 0 0 10px 0;
  font-size: 16px; }

.popover-content {
  padding-left: 0;
  padding-right: 0;
  padding-top: 15px;
  font-size: 13px; }
  .popover-content .row-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px; }

.rotating-gears {
  display: inline-block;
  position: relative;
  padding: 2em 0;
  margin-right: 3.5em; }
  .rotating-gears .gear-top,
  .rotating-gears .gear-bottom {
    position: absolute;
    left: 6em; }
  .rotating-gears .gear-top {
    top: .5em; }
  .rotating-gears .gear-bottom {
    bottom: 1em; }

hr.separator-line {
  border-color: #D9E0D5; }

.border-left {
  border-left: 1px solid #D9E0D5; }

.page-info {
  margin: 0;
  color: #3D5FC0;
  background-color: rgba(61, 95, 192, 0.06);
  padding: 10px 15px;
  border-radius: 5px; }
  .page-info .icon {
    vertical-align: sub;
    margin-right: 5px; }

.spinning-progress {
  text-align: center; }
  .spinning-progress .text {
    display: block;
    font-size: 18px; }

.img-circle {
  border-radius: 50%; }

.btn {
  font-weight: 600;
  line-height: 1.55; }
  .btn svg {
    vertical-align: sub; }

.nav-tabs .nav-link {
  cursor: pointer;
  color: #3D5FC0; }
  .nav-tabs .nav-link.active {
    font-weight: 600; }
    .nav-tabs .nav-link.active:hover, .nav-tabs .nav-link.active:focus {
      background-color: #fff;
      color: inherit; }

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9; }

.demoable-content-wrapper {
  position: relative; }
  .demoable-content-wrapper .demo-overlay {
    display: none; }
  .demoable-content-wrapper.demo .demoable-content {
    opacity: .2; }
  .demoable-content-wrapper.demo .navbar-nav {
    opacity: .7; }
    .demoable-content-wrapper.demo .navbar-nav li > .nav-link {
      pointer-events: none; }
      .demoable-content-wrapper.demo .navbar-nav li > .nav-link:hover {
        text-decoration: none; }
  .demoable-content-wrapper.demo .demo-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 1.5rem;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px); }

.animated-timer {
  position: relative; }
  .animated-timer:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2px;
    width: 11px;
    height: 4px;
    background-color: #000;
    animation: spin 1s linear infinite;
    transform-origin: center left; }

@keyframes spin {
  50% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(360deg); } }
  .animated-timer.timer-small svg {
    width: 18px; }
  .animated-timer.timer-small:after {
    width: 6px;
    height: 2px; }

.wrapper-green-bg {
  background-color: #55a72f; }

.scroll-visible ::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: #fafafa; }

.scroll-visible ::-webkit-scrollbar {
  width: 5px;
  background-color: #ccc; }

.scroll-visible ::-webkit-scrollbar-thumb {
  background-color: #b3b3b3; }

.copyright {
  text-align: center; }

.cursor-pointer {
  cursor: pointer !important; }

.skeleton {
  display: block;
  background-color: #f7f7f7;
  position: relative;
  overflow: hidden;
  text-indent: -1000rem; }
  .skeleton:before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: skeletonLoading 1s cubic-bezier(0.4, 0, 0.2, 1) infinite; }

@keyframes skeletonLoading {
  from {
    left: -150px; }
  to {
    left: 100%; } }

.separator-heading {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 600;
  color: #00943E; }
  .separator-heading span {
    display: block;
    width: 80%; }
  .separator-heading hr {
    margin: 0;
    width: 25%; }

.animate-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px; }

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0); }
  40%, 60% {
    transform: translate3d(4px, 0, 0); } }

.p-0 {
  padding: 0 !important; }

.bg-white {
  background-color: #fff !important; }

b, strong {
  font-weight: 600; }

.list-group-item {
  border-color: #ebeef6; }
  .list-group-item:hover, .list-group-item.active {
    background-color: rgba(235, 238, 246, 0.5);
    border-color: #ebeef6; }
  .list-group-item.active {
    font-weight: 600; }

.gap-0 {
  gap: 0rem !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 0.75rem !important; }

.gap-4 {
  gap: 1rem !important; }

.gap-5 {
  gap: 1.25rem !important; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: #b2b3b5;
  opacity: 0.5; }

.placeholder.btn::before {
  display: inline-block;
  content: ""; }

.placeholder-xs {
  min-height: 0.6em; }

.placeholder-sm {
  min-height: 0.8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

.border {
  border: 1px solid #eceff1; }

.rounded {
  border-radius: .25rem; }

.animated {
  animation-duration: var(--animate-duration);
  animation-fill-mode: both; }

@media print, (prefers-reduced-motion: reduce) {
  .animated {
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important; }
  .animated[class*='Out'] {
    opacity: 0; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeIn {
  animation-name: fadeIn; }

.fadeOut {
  animation-name: fadeOut; }

.check.partial-selected .checkmark:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 2px;
  position: relative;
  bottom: 4px;
  background-color: #00943E; }

.check {
  display: inline-block; }
  .check .check-text {
    color: #9b9b9b;
    font-size: 12px;
    padding: 7px 0 0 0; }

.container-check {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  color: #021c3f;
  font-size: 13px;
  margin: 0px !important;
  user-select: none; }
  .container-check input {
    display: none;
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .container-check input:checked ~ .checkmark {
      background-color: #00943E !important;
      border-color: #00943E; }
      .container-check input:checked ~ .checkmark:after {
        display: block;
        border-color: #fff; }
  .container-check.indeterminate .checkmark {
    background-color: #00943E;
    border-color: #00943E; }
    .container-check.indeterminate .checkmark::after {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      left: 4px;
      width: 10px;
      height: 1.5px;
      background-color: #fff;
      border-width: 0;
      transform: scale(0);
      transform-origin: center;
      animation: indeterminate-check 125ms 125ms cubic-bezier(0.4, 0, 0.23, 1) forwards; }
  .container-check .checkmark {
    position: absolute;
    top: -2px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: rgba(133, 194, 66, 0.2);
    border-radius: 5px;
    transition: all .125s ease-out;
    background-color: #fff;
    border: 1px solid #CECECE; }
  .container-check .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    width: 5px;
    height: 11px;
    left: 3px;
    top: -1px;
    border: solid #fff;
    border-width: 0 2.5px 2.5px 0;
    transform: rotate(45deg) scale(0);
    border-radius: 2px;
    transform-origin: 0% 100%;
    animation: checkbox-check 125ms 125ms cubic-bezier(0.4, 0, 0.23, 1) forwards; }

@keyframes checkbox-check {
  0% {
    transform: rotate(45deg) scale(0); }
  33% {
    transform: rotate(45deg) scale(0.33); }
  100% {
    transform: rotate(45deg) scale(1); } }

@keyframes indeterminate-check {
  0% {
    transform: scale(0); }
  33% {
    transform: scale(0.33); }
  100% {
    transform: scale(1); } }

.check.check-btn {
  display: inline-block;
  margin-bottom: 0;
  padding: .5rem .8rem;
  background: #fff;
  border: 1px solid #bbccc2;
  border-radius: 3px;
  opacity: .5; }
  .check.check-btn.active {
    background: #00943E;
    border-color: #00943E;
    color: #fff;
    opacity: 1; }
    .check.check-btn.active .container-check {
      color: #fff; }

.radio-warehouse {
  cursor: pointer; }
  .radio-warehouse input[type="radio"] {
    display: none; }
    .radio-warehouse input[type="radio"]:checked + .warehouse-badge {
      background-color: #4d4d4d;
      color: #fff; }

.form-control:focus {
  border-color: rgba(0, 0, 0, 0.15); }

.form-control:disabled {
  color: #888d92; }

.input-group.transparent-addon .form-control {
  border-right: 0; }

.input-group.transparent-addon .input-group-text {
  background-color: #fff;
  border-left: 0; }

.input-group.transparent-addon .btn {
  padding-left: 0;
  padding-right: .5rem;
  border-left: 0;
  background-color: #fff; }

input[type="number"] {
  -moz-appearance: textfield; }
  input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

.switch {
  display: flex;
  align-items: center;
  height: auto;
  padding: 1px;
  border: 1px solid #ccc;
  border-radius: 2px; }
  .switch input[type="radio"] {
    display: none; }
    .switch input[type="radio"]:checked + span {
      display: block;
      background-color: #00943E;
      color: #fff;
      border-radius: 2px; }
  .switch label {
    display: block;
    margin: 0 !important;
    height: 100%;
    flex: 1 1 auto;
    text-align: center;
    cursor: pointer;
    background-color: #F4F4F4;
    color: #858C82;
    font-weight: 600;
    transition: all .2s ease-out; }
  .switch span {
    height: 100%;
    line-height: 2.22; }

.input-help {
  display: block;
  margin-top: 10px;
  font-size: 13px;
  color: #a5a5a5; }

.input-group-addon {
  background-color: #fff;
  border-left: 0; }

.switch-checkbox {
  display: block;
  position: relative;
  margin-bottom: 0 !important;
  cursor: pointer; }
  .switch-checkbox i {
    border-radius: 1rem;
    content: '';
    display: block;
    min-width: 52px;
    height: 26px;
    border: 1px solid #D9DED5;
    background: #F1F4EF;
    color: #B6BAB3;
    font-style: normal; }
    .switch-checkbox i:before {
      border-radius: 1rem;
      transition: all .1s ease-in;
      content: '';
      display: block;
      position: absolute;
      top: 6px;
      right: 4px;
      width: 15px;
      height: 15px;
      background-color: #B6BAB4; }
    .switch-checkbox i:after {
      content: attr(data-swoff-text);
      position: absolute;
      left: 0;
      text-align: center;
      min-width: 3em;
      font-weight: 600;
      font-size: 12px;
      line-height: 26px;
      color: #929aa5; }
  .switch-checkbox input {
    position: absolute;
    left: -9999px; }
    .switch-checkbox input:checked + i {
      background-color: #00943E;
      border-color: #fff; }
    .switch-checkbox input:checked + i:before {
      transform: translateX(-27px);
      background-color: #fff; }
    .switch-checkbox input:checked + i:after {
      content: attr(data-swon-text);
      color: #fff;
      left: auto;
      right: 0; }

.switch-ACTIVE-INACTIVE .switch-checkbox i {
  min-width: 100px; }
  .switch-ACTIVE-INACTIVE .switch-checkbox i:before {
    right: .2em; }
  .switch-ACTIVE-INACTIVE .switch-checkbox i:after {
    min-width: 5.5em; }

.switch-ACTIVE-INACTIVE .switch-checkbox input:checked + i:before {
  right: 3em; }

.input-group .input-group-append .btn-primary {
  background-color: #3D5FC0;
  border-color: #3D5FC0; }

.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex; }

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2; }

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: .9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #a0a1a5;
  text-align: center;
  white-space: nowrap;
  background-color: #f0f1f5;
  border: 1px solid #E7E7F0;
  border-radius: 0.25rem; }

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0; }

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.editable-cell-input button {
  cursor: pointer; }

.shipment-item {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #E0E3E0;
  background-color: #fff;
  border-radius: 6px;
  cursor: pointer; }
  .shipment-item:hover {
    box-shadow: none; }
  .shipment-item.active {
    border-color: #00943E; }
    .shipment-item.active .shipment-header {
      padding-bottom: 0; }
      .shipment-item.active .shipment-header:after {
        content: "";
        display: block;
        margin: 20px 0;
        width: 100%;
        height: 1px;
        border-top: 1px solid #E0E3E0; }
    .shipment-item.active .shipment-info {
      display: block; }
  .shipment-item .icon-shipment-done {
    text-align: right;
    color: #00943E; }
  .shipment-item .warehouse-big {
    text-align: right;
    font-size: 3rem;
    font-weight: 700;
    color: #a4a7b1; }

.shipment-header {
  padding-bottom: 20px;
  font-size: 13px; }
  .shipment-header .shipment-title {
    margin-bottom: 3px;
    font-size: 14px;
    font-weight: 600;
    overflow-wrap: break-word;
    line-height: 1.3; }
  .shipment-header .badge {
    margin: 5px 0 0 0; }
  .shipment-header .btn-sm {
    font-size: 11px; }

.popover-shipment {
  color: black; }

.shipment-box {
  display: inline-block;
  position: relative;
  text-align: center;
  font-size: 12px;
  cursor: pointer; }
  .shipment-box .toggle-view {
    margin-bottom: .5em;
    color: #858c81; }
  .shipment-box .box-number,
  .shipment-box .box-weight {
    display: block; }
  .shipment-box .box-number {
    position: absolute;
    top: 36px;
    width: 100%;
    color: #fff; }
  .shipment-box .box-weight {
    font-weight: 600; }
  .shipment-box.box-warning {
    color: #FCB036; }
    .shipment-box.box-warning svg path {
      fill: #FCB036; }
  .shipment-box.box-success {
    color: #00943E; }
    .shipment-box.box-success svg path {
      fill: #00943E; }
  .shipment-box.box-danger {
    color: #FF7158; }
    .shipment-box.box-danger svg path {
      fill: #FF7158; }
  .shipment-box.box-selected > svg path {
    fill: #3D5FC0; }
  .shipment-box.box-add {
    color: #585F55; }
    .shipment-box.box-add .box-number {
      top: 1.1em; }
    .shipment-box.box-add svg path {
      fill: #DAE4D4; }
    .shipment-box.box-add:hover {
      text-decoration: underline; }
      .shipment-box.box-add:hover svg path {
        fill: #cddac4; }

.shipment-boxes .shipment-box {
  margin: 0 10px 10px 0; }
  .shipment-boxes .shipment-box:first-child {
    margin-left: 0; }

.shipment-info {
  margin-bottom: 15px;
  color: #585F55; }
  .shipment-info > span {
    margin-right: 15px; }
    .shipment-info > span svg {
      position: relative;
      top: 2px;
      margin-right: 3px; }
  .shipment-info .btn-missing-items {
    display: inline-block;
    font-weight: normal !important;
    color: #FF7158; }
    .shipment-info .btn-missing-items span {
      text-decoration: underline; }
    .shipment-info .btn-missing-items:hover span {
      text-decoration: none; }
    .shipment-info .btn-missing-items.no-missing {
      color: #038037; }
  .shipment-info .text-success {
    color: #038037 !important; }
  .shipment-info .no-missing-items {
    color: #00943E; }
  .shipment-info .btn-missing-items .icon,
  .shipment-info .no-missing-items .icon {
    margin-right: 0;
    top: -2px; }

.shipments .check {
  transform: translateY(-50%); }

.select-all-shipment {
  font-weight: 600; }

.popover-shipment {
  min-width: 400px !important;
  max-width: none; }
  .popover-shipment table td {
    border: none; }
    .popover-shipment table td:first-child {
      width: 28%;
      padding-left: 0 !important; }
  .popover-shipment .box-items-qnty {
    text-align: left;
    margin-top: 5px;
    color: green;
    font-weight: bold; }

.shipment-search-result .result-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0 15px 0;
  margin-bottom: 12px;
  border-bottom: 1px solid #EDF1EA; }
  .shipment-search-result .result-item:last-child {
    border-bottom: 0; }
  .shipment-search-result .result-item.highlight {
    background-color: rgba(255, 255, 156, 0.08); }
  .shipment-search-result .result-item .media {
    position: relative;
    width: 50px;
    margin-right: 8px;
    background-color: #ececec; }
  .shipment-search-result .result-item .info {
    flex: 1 1 auto;
    font-size: 13px; }
    .shipment-search-result .result-item .info .info-row > span {
      color: #858C82; }
      .shipment-search-result .result-item .info .info-row > span:after {
        content: "";
        display: inline-block;
        margin: 0 8px 0 10px;
        width: 1px;
        height: 20px;
        border-left: 1px solid #CECECE;
        vertical-align: middle; }
      .shipment-search-result .result-item .info .info-row > span:last-child:after {
        display: none; }
      .shipment-search-result .result-item .info .info-row > span strong {
        font-weight: normal;
        color: #2c2f2a; }
  .shipment-search-result .result-item .dropdown-move .dropdown-menu {
    min-width: 12rem;
    display: flex;
    padding: 10px; }
  .shipment-search-result .result-item .info-row {
    margin-bottom: 5px; }
  .shipment-search-result .result-item .btn-move {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: .1; }
    .shipment-search-result .result-item .btn-move .icon {
      margin-right: 2px;
      vertical-align: middle; }
  .shipment-search-result .result-item .right {
    text-align: right; }
  .shipment-search-result .result-item .badge-done {
    margin: 0;
    font-size: .7rem;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0; }
  .shipment-search-result .result-item .warehouse-badge {
    margin-left: 0;
    margin-right: 0; }

.table-missing-items {
  table-layout: auto !important;
  margin-top: 1rem; }
  .table-missing-items thead th,
  .table-missing-items tbody td {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    vertical-align: middle; }
  .table-missing-items thead th {
    font-weight: 700; }
  .table-missing-items .col-image {
    width: 5%; }
  .table-missing-items .col-title {
    width: 35%;
    text-align: left; }
  .table-missing-items .picture img {
    max-width: 2rem; }
  .table-missing-items .product-title {
    font-size: .9rem;
    font-weight: normal; }

.shipment-status {
  color: #585F55;
  text-align: right; }

@keyframes flashing {
  0% {
    background-color: rgba(255, 255, 156, 0.7); }
  100% {
    background-color: rgba(255, 255, 156, 0); } }

.animate-flashing {
  animation: flashing 1s ease-out; }
