.col-align-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.feed-items-window {
  display: flex;
  flex-direction: column;
  max-height: 500px !important;
  overflow-y: auto; }

.add-per-condition .condition-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .5rem; }
  .add-per-condition .condition-item .condition {
    width: 30rem; }
  .add-per-condition .condition-item .current-qty {
    margin: 0 1rem; }
  .add-per-condition .condition-item .qty {
    width: 9rem; }

.duplicate-asin-modal .media img {
  max-height: 200px; }
