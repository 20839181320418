// ======== Theme Blue
$jackal-orange: #FF9900;
$brand-primary: $jackal-orange;
$border-color: #e6e9ec;

// ------- site-nav
// ----------------
$site-nav-bg:                       $md-white;
$site-nav-toggle-color:             $brand-primary;
$site-nav-head-color:               $md-grey-900;
$site-nav-head-border-color:        $md-green-50;
$site-nav-list-color:               #272A39;
$site-nav-list-hover-color:         #00943E;

// ------ view-header
// ------------------
$view-header-bg:                    #fff;


/// MORE COMING SOON 


/// ####### END #######
