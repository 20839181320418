.ebay-stat-metrics {
  display: flex;
  position: absolute;
  right: 0; }
  .ebay-stat-metrics .metric-item {
    margin: 0 1rem;
    padding: 0;
    border: 0; }
    .ebay-stat-metrics .metric-item h6 {
      margin-bottom: 0;
      color: #00943E; }
    .ebay-stat-metrics .metric-item span {
      color: #4B6C59; }
