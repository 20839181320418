.custom-sku-selector .unused-items {
  display: inline-block;
  font-weight: normal;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
  background-color: #f5f6f8;
  color: #62708c;
  border-color: #e6e9ec;
  margin: 5px; }

.custom-sku-selector .sku-template {
  display: inline-flex;
  font-weight: normal;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
  color: #00943E;
  background-color: rgba(0, 148, 62, 0.05);
  border-color: rgba(0, 148, 62, 0.3);
  margin: 5px; }
  .custom-sku-selector .sku-template .btn-link {
    line-height: 0; }

.custom-sku-selector .presetsTableBorderLeft {
  vertical-align: middle;
  border: 1px solid #e5e5e5;
  border-left: 1px solid transparent; }

.custom-sku-selector .presetsTableBorderRight {
  vertical-align: middle;
  border: 1px solid #e5e5e5;
  border-right: 1px solid transparent; }

.custom-sku-selector .SKU-titleText {
  font-weight: normal;
  font-size: 14px; }

.custom-sku-selector .dashedZone {
  padding: 1rem;
  border: 1px dashed #ccc;
  border-radius: 5px; }

.custom-sku-selector .card-example-sku {
  background-color: #fff;
  transition: background-color .3s ease-in-out; }
  .custom-sku-selector .card-example-sku.highlight .dashedZone {
    background-color: rgba(255, 255, 0, 0.1); }

.custom-sku-selector .card {
  margin-bottom: 2rem; }

.custom-sku-selector .card-title {
  margin-bottom: .8rem; }
