.col-align-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.feed-items-window {
  display: flex;
  flex-direction: column;
  max-height: 500px !important;
  overflow-y: auto; }

.replenish-modal.modal-dialog {
  max-width: 80vw; }

.replenish-modal .modal-title {
  font-size: 20px;
  font-weight: 600; }

.replenish-modal .badge {
  font-size: 14px;
  padding: 8px; }

.replenish-modal .table {
  margin-top: 15px; }
  .replenish-modal .table thead th, .replenish-modal .table tr td {
    text-align: center;
    vertical-align: middle;
    padding: 0.3rem;
    word-break: break-all; }
    .replenish-modal .table thead th.small-column, .replenish-modal .table tr td.small-column {
      min-width: 50px; }
    .replenish-modal .table thead th.input-cell, .replenish-modal .table tr td.input-cell {
      min-width: 80px; }
      .replenish-modal .table thead th.input-cell input, .replenish-modal .table tr td.input-cell input {
        min-width: 40px;
        max-width: 60px;
        text-align: center;
        display: table-cell; }
  .replenish-modal .table th {
    font-size: 12px; }

.delete-dialog .icon-warning {
  width: 32px;
  height: 32px;
  margin-bottom: 1rem; }

.delete-dialog-title {
  font-size: 1.1rem;
  font-weight: 600; }

.delete-dialog-description {
  font-size: 1rem; }
