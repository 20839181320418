.table-generator-default-view .info {
  margin: 0;
  padding: 3em;
  font-size: 22px;
  font-weight: 600; }

.table-generator-default-view.speed-mode {
  padding-top: 5px;
  padding-bottom: 25px; }

.info-empty-batch {
  position: relative; }
  .info-empty-batch .text {
    display: inline-block;
    width: 80%; }
  .info-empty-batch .arrow {
    position: absolute; }
    .info-empty-batch .arrow path {
      fill: #cccfd6; }
  .info-empty-batch .left-arrow {
    top: .5em;
    left: 1.5em; }
  .info-empty-batch .right-arrow {
    right: 1em;
    bottom: -1.5em; }

.listing-details .header {
  display: flex;
  margin-bottom: 20px;
  padding: .5rem; }
  .listing-details .header > .info {
    flex: 1 1 auto; }

.listing-details .book-picture {
  max-width: 40px;
  max-height: 75px;
  margin-right: 15px; }

.listing-details .book-title {
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  overflow-wrap: anywhere; }

.listing-details .meta-info p {
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 500; }
  .listing-details .meta-info p strong {
    font-weight: 500;
    color: #687278;
    text-transform: uppercase; }

.listing-details .section {
  padding: .5rem 1rem; }
  .listing-details .section .section-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: .8em 0;
    color: #3A3F37;
    font-size: 16px;
    font-weight: 600; }
    .listing-details .section .section-title svg {
      margin-right: 5px;
      vertical-align: bottom; }
    .listing-details .section .section-title a {
      font-size: 1rem; }
  .listing-details .section .left-section,
  .listing-details .section .right-section {
    flex: 1; }
  .listing-details .section .subsection-title-boxed {
    display: inline-block;
    padding: .3rem .5rem;
    position: relative;
    top: -1.4rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: .9rem;
    font-weight: 600;
    background-color: #90a1b3;
    color: #fff; }

.listing-details .input-group-addon {
  border-left: 1px solid #cfd8dc;
  background-color: #eceff1; }

.listing-details .input-group .input-group-append .btn-success.disabled, .listing-details .input-group .input-group-append .btn-success:disabled {
  background-color: #00943E;
  border-color: #00943E; }

.prices-box .prices-header {
  padding: .4rem .8rem;
  margin-bottom: 0;
  border-right: 1px solid #e0e3e7;
  font-size: 12px;
  font-weight: 600;
  color: #464a4c;
  background-color: #eef0f2;
  text-transform: uppercase; }
  .prices-box .prices-header span {
    font-weight: normal;
    font-size: 11px; }

.prices-box .scrollable {
  overflow-y: auto;
  max-height: 165px; }
  .prices-box .scrollable::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background-color: #fafafa; }
  .prices-box .scrollable::-webkit-scrollbar {
    width: .3rem; }
  .prices-box .scrollable::-webkit-scrollbar-thumb {
    border-radius: .5rem;
    background-color: #E0E1E6; }

.prices-box .price-item {
  display: block;
  padding: .2rem 0.6rem;
  margin: 0 .2rem;
  font-size: 12px;
  font-weight: 400;
  color: #3c404c;
  border: 1px solid transparent;
  border-radius: 3px; }
  .prices-box .price-item span {
    color: #a5acb1;
    font-size: 11px; }
  .prices-box .price-item .badge-secondary {
    background-color: rgba(165, 172, 177, 0.2);
    color: #a5acb1;
    margin-left: .3rem; }
  .prices-box .price-item:hover {
    color: #3D5FC0; }
  .prices-box .price-item.selected, .prices-box .price-item:focus {
    background-color: rgba(61, 95, 192, 0.1);
    color: #3D5FC0;
    font-weight: 500; }

.prices-box.new .price-item span:first-child {
  display: none; }

.prices-box:last-child .prices-header {
  border-right: 0; }

.prices-box ::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: transparent; }

.prices-box ::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: transparent; }

.prices-box ::-webkit-scrollbar-thumb {
  background-color: transparent; }

.prices-box-group {
  display: flex;
  justify-content: flex-start; }
  .prices-box-group .prices-box {
    min-width: 33.3%;
    border-left: 1px solid #e6e9ec; }
    .prices-box-group .prices-box:first-child {
      padding-left: 0;
      border-left: 0; }

.profit-calculator {
  display: flex;
  justify-content: flex-start; }
  .profit-calculator label {
    font-weight: 500;
    font-size: 14px !important; }
  .profit-calculator .form-group {
    margin: 0 1%; }
    .profit-calculator .form-group:first-child {
      margin-left: 0; }
  .profit-calculator .input-group {
    width: 7rem; }
    .profit-calculator .input-group-addon, .profit-calculator .input-group input {
      font-size: 1.1rem;
      font-weight: 500; }
  .profit-calculator .result {
    position: relative;
    border: 1px transparent solid;
    border-radius: 2px; }
    .profit-calculator .result .value {
      display: block;
      padding: .55rem 1rem;
      border-radius: 3px;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1;
      color: #00943E;
      background-color: rgba(0, 148, 62, 0.1);
      white-space: nowrap; }
      .profit-calculator .result .value.loss {
        background-color: rgba(255, 113, 88, 0.1);
        color: #FF7158; }
    .profit-calculator .result-bep {
      margin-top: .2rem;
      color: #636c72; }
      .profit-calculator .result-bep label {
        font-weight: normal; }
      .profit-calculator .result-bep-value {
        margin-left: .3rem;
        display: inline-block;
        font-weight: 600; }
    .profit-calculator .result .popover {
      display: none;
      top: 7em;
      left: -4em; }
      .profit-calculator .result .popover.show {
        display: block; }
  .profit-calculator .invalid-result {
    border: 1px red solid;
    border-radius: 2px; }
  .profit-calculator .input-group-addon {
    line-height: 1; }
  .profit-calculator a {
    margin: 0 !important;
    color: #3A3F37;
    text-decoration: underline;
    font-size: 12px; }
    .profit-calculator a:hover {
      text-decoration: none; }

.projected-details .popover-title {
  background: #fff;
  padding: 0 0 10px 0;
  font-size: 16px; }

.projected-details .popover-content {
  padding-left: 0;
  padding-right: 0;
  padding-top: 15px;
  font-size: 13px; }

.projected-details .row-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid #e6e9ec; }
  .projected-details .row-item:last-child {
    border-bottom: 0; }
  .projected-details .row-item.font-bold strong {
    font-weight: bold; }

.btn-condition {
  line-height: 1;
  padding: 6px 8px !important; }
  .btn-condition.selected {
    background-color: #3D5FC0;
    color: #fff; }

.btn-condition-group .btn-condition {
  margin: 2px; }

.notes-box {
  flex: 0 0 150px;
  margin: 0 5px 1px 5px;
  padding: 5px;
  border: 1px solid #e6e9ec;
  border-radius: 5px;
  background-color: #fff; }
  .notes-box .notes-box-header {
    margin: -5px -5px 10px -5px;
    padding: 10px 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    background-color: #f1f4f8;
    text-align: center; }
  .notes-box .btn-notes-item {
    display: block; }
    .notes-box .btn-notes-item.selected {
      background-color: #3D5FC0;
      color: #fff; }

.notes-item-group {
  overflow-y: auto;
  max-height: 8rem; }
  .notes-item-group .form-check-label {
    display: block;
    padding: .3rem .5rem;
    font-weight: 500;
    border: 1px solid #e9eaec;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(250, 250, 250, 0.95) 100%);
    color: #3c404c;
    border-radius: 4px; }
    .notes-item-group .form-check-label::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0px 1px 3px rgba(255, 255, 255, 0.3);
      border-radius: 3px;
      border-bottom: 2px solid #e9eaec; }
    .notes-item-group .form-check-label:hover {
      background: linear-gradient(180deg, #FAFAFA 0%, rgba(244, 245, 245, 0.95) 100%) !important; }
    .notes-item-group .form-check-label:active::before {
      box-shadow: none;
      border-bottom-width: 1px; }
    .notes-item-group .form-check-label input {
      display: none; }

.notes-box-group {
  display: flex;
  align-items: stretch;
  overflow: auto;
  padding-bottom: 1rem; }
  .notes-box-group .notes-box:first-child {
    margin-left: 0; }
  .notes-box-group .notes-box:last-child {
    margin-right: 0; }

.horizontal-scrollable {
  overflow: auto;
  width: 100%;
  padding-bottom: 4px; }
  .horizontal-scrollable::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background-color: #fff; }
  .horizontal-scrollable::-webkit-scrollbar {
    width: 0;
    height: 10px;
    border-radius: 5px;
    background-color: #CECECE; }
  .horizontal-scrollable::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #CECECE; }

.popover-bbprice.bs-popover-right {
  left: 1em !important; }

.badge-stat-group {
  display: flex;
  align-items: center; }
  .badge-stat-group strong {
    font-size: .9rem; }

.badge-stat {
  min-width: 77px;
  text-align: center;
  display: inline-block;
  padding: .15em 1rem;
  font-size: 1.3rem;
  font-weight: 600;
  border-radius: 8px;
  background-color: #eceff1;
  color: #464a4c; }
  .badge-stat.orange {
    color: #FCB036;
    background-color: rgba(252, 176, 54, 0.1); }
  .badge-stat.blue {
    color: #0064d2;
    background-color: rgba(0, 100, 210, 0.1); }
  .badge-stat.purple {
    color: #a116a2;
    background-color: rgba(161, 22, 162, 0.1); }
  .badge-stat.skeleton {
    min-width: 97px;
    height: 32.73px; }
  .badge-stat small {
    font-size: .8rem; }

.listing-marketplace-option {
  height: 40px;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: .2rem .4rem;
  border: 1px solid #D9E0D5;
  background-color: #fff;
  border-radius: 3px;
  margin: 0px .2rem;
  cursor: pointer; }
  .listing-marketplace-option.selected {
    border-color: #D9E0D5;
    background-color: #F7F9F6; }
    .listing-marketplace-option.selected .checkmark-icon {
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
      background: #fff;
      border-radius: 50%;
      border: 1px solid #FFF; }
  .listing-marketplace-option .left {
    text-align: center; }
  .listing-marketplace-option .bbprice-item {
    display: flex;
    justify-content: space-between;
    font-size: .9rem;
    line-height: 1.3; }
    .listing-marketplace-option .bbprice-item .badge {
      font-size: .7rem; }
  .listing-marketplace-option .tld {
    display: block;
    font-size: .9rem; }

.subsection-title {
  font-weight: 600;
  font-size: 1rem;
  padding-bottom: .7rem;
  margin-bottom: .5rem; }

.projected-profit-bottom {
  display: flex;
  gap: 1rem; }
  .projected-profit-bottom .tab-content {
    padding-top: .5rem; }
  @media screen and (min-width: 1400px) {
    .projected-profit-bottom {
      gap: 2rem; } }

.projected-profit .title {
  display: block;
  font-size: .95rem;
  margin-bottom: .5rem;
  font-weight: 600; }

.profit-channel .nav {
  margin-top: .5rem; }

.profit-channel .nav-link {
  font-weight: 600;
  display: flex;
  gap: .5rem;
  opacity: .75; }
  .profit-channel .nav-link, .profit-channel .nav-link:focus {
    color: #3c404c; }
  .profit-channel .nav-link.active {
    opacity: 1;
    color: #3c404c !important; }

.projected-details-inner {
  margin-top: .3rem;
  font-size: .9rem; }
  .projected-details-inner strong {
    margin-right: .5rem;
    font-weight: normal;
    font-size: .9rem; }
  .projected-details-inner hr {
    margin: .5rem 0; }

.btn-ebay {
  background-color: #0064d2;
  color: #fff; }
  .btn-ebay:hover, .btn-ebay:focus {
    background-color: #0058b9; }
  .btn-ebay .ebay-logo {
    margin-left: .3rem; }

.btn-sbyb {
  background-color: #00AEEF;
  color: #fff; }
  .btn-sbyb:hover, .btn-sbyb:focus {
    background-color: #009bd6; }

.sbyb-details-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem;
  padding-bottom: .5rem;
  border-bottom: 1px solid #D9E0D5; }
  .sbyb-details-item:last-child {
    border-bottom: 0; }
  .sbyb-details-item .value {
    font-size: 1.4rem;
    font-weight: 700; }
  .sbyb-details-item .description {
    margin-left: 1rem;
    margin-bottom: 0;
    font-size: 0.95rem; }

.section-vertical-separator {
  margin: 0 2rem;
  border-left: 1px solid #e6e9ec; }

.section-horizontal-separator {
  margin: 1.2rem 0;
  border-top: 1px solid #e6e9ec; }
