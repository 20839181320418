.category-chart .category-nav {
  padding-left: 2rem; }
  .category-chart .category-nav ul {
    list-style: none;
    margin-left: .5rem;
    padding: 0; }
    .category-chart .category-nav ul > li {
      display: inline-block; }

.category-chart .recharts-custom-tooltip {
  background: #fff;
  border: 1px solid #ccc;
  padding: 1rem; }

.category-chart .empty-data-message {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa; }

.category-chart.chart-grid-legend .recharts-default-legend {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%; }
  .category-chart.chart-grid-legend .recharts-default-legend .recharts-legend-item {
    font-size: 12px; }
