.modal-dialog-template-editor {
  max-width: 90rem; }

.tab-content-repricer-template {
  margin: 1rem 3rem 0 3rem; }
  .tab-content-repricer-template .content-header {
    margin-bottom: 1rem;
    width: 26rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
    .tab-content-repricer-template .content-header .heading {
      font-size: 1.5rem; }
    .tab-content-repricer-template .content-header .description {
      color: #757575; }
    .tab-content-repricer-template .content-header:after {
      content: "";
      display: inline-block;
      width: 5rem;
      height: .15rem;
      background-color: #e7e9ef; }
  .tab-content-repricer-template .form-group {
    margin-bottom: 1.5rem;
    position: relative; }
    .tab-content-repricer-template .form-group label {
      font-weight: 600;
      font-size: 1rem !important; }
      .tab-content-repricer-template .form-group label.form-check-label {
        font-weight: normal; }
  .tab-content-repricer-template .form-check-label.d-block {
    margin-bottom: .5rem !important; }
  .tab-content-repricer-template > div[class^="alert-"] {
    width: 26rem;
    position: relative;
    top: 4rem; }
  .tab-content-repricer-template .scrollable {
    overflow-y: auto;
    max-height: 500px;
    padding-right: .5rem; }
  .tab-content-repricer-template .step-number {
    flex: 0 0 auto;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    background-color: #54cd82;
    color: #fff;
    font-weight: 700;
    font-size: 1.4rem;
    border-radius: 50%; }
  .tab-content-repricer-template .alert-tooltip {
    position: absolute;
    bottom: 0.4rem;
    right: -2.5rem;
    cursor: pointer; }
  .tab-content-repricer-template .d-inline-flex .alert-tooltip,
  .tab-content-repricer-template .d-flex .alert-tooltip {
    position: relative;
    top: 0;
    right: -.5rem; }
  .tab-content-repricer-template .card-toggle .card-header {
    border: 1px solid #E7E7F0;
    background: #fff;
    color: inherit;
    border-radius: 5px; }
    .tab-content-repricer-template .card-toggle .card-header.active {
      background: #f9f9fb;
      border-color: #eaeaf1;
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
  .tab-content-repricer-template .card-toggle > .collapse,
  .tab-content-repricer-template .card-toggle > .collapsing {
    background: #fff;
    border: 1px solid #eaeaf1;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; }
  .tab-content-repricer-template .scrollable .card-toggle {
    margin-bottom: 1rem; }
  .tab-content-repricer-template .Select--multi .Select-input {
    max-width: 20rem; }
  .tab-content-repricer-template h1 .icon-help, .tab-content-repricer-template h2 .icon-help, .tab-content-repricer-template h3 .icon-help, .tab-content-repricer-template h4 .icon-help, .tab-content-repricer-template h5 .icon-help, .tab-content-repricer-template h6 .icon-help,
  .tab-content-repricer-template .card-header .icon-help,
  .tab-content-repricer-template .form-group .icon-help,
  .tab-content-repricer-template label .icon-help {
    margin-left: .3rem;
    color: #999EAE; }

.alert-tooltip-inner {
  max-width: none;
  text-align: left; }

.scroll-shadow {
  height: 34px;
  position: sticky;
  top: -34px;
  z-index: 1;
  color: white;
  text-align: center;
  -webkit-backface-visibility: hidden; }
  .scroll-shadow:before, .scroll-shadow:after {
    content: '';
    display: block;
    height: 16px;
    background-color: #fff;
    position: sticky; }
  .scroll-shadow:before {
    top: 0;
    box-shadow: -4px 0 5px rgba(0, 0, 0, 0.5); }
  .scroll-shadow:after {
    background: linear-gradient(white 10%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.4) 70%, transparent);
    top: 0;
    z-index: 2; }
  .scroll-shadow > div {
    background: #fff;
    height: 20px;
    position: sticky;
    top: 0px;
    margin-top: -16px;
    z-index: 3; }

.custom-repricing {
  padding: 1.3rem;
  border: 1px solid #e7e9ef;
  border-radius: 5px; }

.step-offer-count .collapse-inner {
  margin-left: 1.8rem;
  border: 1px solid #e7e9ef;
  padding: 1rem 1.5rem;
  border-radius: 3px; }
  .step-offer-count .collapse-inner.collapse-adjustment {
    margin-left: 0;
    background-color: #fafafa; }
  .step-offer-count .collapse-inner .Select.d-inline-block {
    width: 20%; }

.help-textbox {
  padding: 1rem 1.5rem;
  border-radius: 3px;
  border: 1px solid #CD2828; }
  .help-textbox .heading {
    font-size: 1.3rem;
    font-weight: 600; }

.form-group .d-flex {
  align-items: center; }
  .form-group .d-flex > label {
    flex: 0 0 auto;
    margin-bottom: 0 !important; }

.btn-tutorial-video {
  float: right;
  position: relative;
  right: 10rem;
  top: .5rem;
  box-shadow: none;
  color: inherit; }

@media screen and (max-width: 1415px) {
  .modal-dialog-template-editor .modal-footer {
    padding-right: 6rem; } }
