.card-profit-breakdown {
  box-shadow: none;
  border: 1px solid #e8eaef;
  border-radius: 6px;
  text-align: center;
  cursor: pointer; }
  .card-profit-breakdown.result {
    cursor: auto; }
  .card-profit-breakdown .value {
    display: block;
    font-size: 24px;
    font-weight: 600; }
  .card-profit-breakdown .title {
    display: block;
    text-transform: uppercase; }
  .card-profit-breakdown .icon {
    position: absolute;
    top: .5rem;
    right: .5rem;
    color: #878A8C; }
