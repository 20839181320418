.Toastify__toast {
  min-height: 40px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  cursor: auto; }
  .Toastify__toast--error {
    background: #c75246;
    box-shadow: 0px 3px 8px rgba(255, 0, 0, 0.5); }
  .Toastify__toast--success {
    background: #00c854;
    box-shadow: 0px 3px 8px rgba(0, 200, 84, 0.5); }

.Toastify__toast-body {
  padding: 0 .5rem;
  font-weight: 600;
  line-height: 1.4; }

.Toastify__progress-bar {
  height: 3px;
  background-color: rgba(255, 255, 255, 0.3); }
