.view .view-content.repricer-app {
  margin-top: 0; }
  .view .view-content.repricer-app .metrics-vertical .metric-item {
    margin-bottom: 1rem;
    min-width: 18rem;
    flex: 1; }

.simple-metric-group {
  display: flex;
  justify-content: center; }

.simple-metric-item {
  margin: 0 4rem;
  text-align: center; }
  .simple-metric-item > span {
    display: block; }
  .simple-metric-item .title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #3A3F37; }
  .simple-metric-item .value {
    font-size: 2rem;
    font-weight: 600;
    color: #3D5FC0; }
  .simple-metric-item .change {
    display: block;
    font-size: 1.2rem; }
    .simple-metric-item .change svg {
      position: relative;
      top: -.1rem; }
