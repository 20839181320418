.reprice-chart {
  position: relative; }
  .reprice-chart .chart-overlay {
    display: none; }
  .reprice-chart.demo .recharts-responsive-container {
    opacity: .2; }
  .reprice-chart.demo .chart-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 1.5rem;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); }
