.loader-container {
  display: flex;
  justify-content: center;
  align-items: center; }
  .loader-container .loader {
    border: 7px solid white;
    border-top: 7px solid #00943E;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 2s linear infinite; }

.StripeElement {
  box-shadow: none;
  margin: 0px;
  border: 1px solid #d6dbde;
  border-radius: 4px;
  width: 100%;
  height: 48px;
  font-size: 18px !important;
  padding: 1rem; }

.StripeElement--focus {
  border: 1px solid #00943E; }

.register-plans {
  margin-bottom: 1.5rem; }
  .register-plans .pricing-selection {
    display: flex;
    justify-content: center; }
    .register-plans .pricing-selection .plan-box {
      position: relative;
      width: 18rem;
      margin: 0 1rem;
      padding: 1rem 1.1rem 3.3rem 1.1rem;
      border: 1px solid #ebeff1;
      border-radius: 5px;
      box-shadow: 0 4px 12px 0 rgba(21, 22, 32, 0.08);
      color: #383838; }
      .register-plans .pricing-selection .plan-box .price {
        position: absolute;
        width: 100%;
        bottom: .5rem;
        left: 0;
        font-size: 1.3rem;
        text-align: center;
        color: #00943E; }
        .register-plans .pricing-selection .plan-box .price small {
          color: #9b9b9b;
          font-weight: 600; }
      .register-plans .pricing-selection .plan-box small {
        font-size: 90%; }
      .register-plans .pricing-selection .plan-box:hover {
        cursor: pointer;
        box-shadow: none; }
      .register-plans .pricing-selection .plan-box.selected {
        background-color: #00943E;
        color: #FFF;
        border-color: #bdefd2;
        box-shadow: 0 0 10px rgba(21, 230, 107, 0.5);
        font-weight: 600; }
        .register-plans .pricing-selection .plan-box.selected .plan-box-features {
          list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAMCAYAAAC9QufkAAAAAXNSR0IArs4c6QAAAKtJREFUKBWVjzsOwjAQRG06ShA1OQ+HgFtF4he4ERUcgZ6SAmTeAoNWxrKSlSb70b5ZJ4QBkVKKqEVn1PRGv2BHVlx6wWzbRQ+aQfuGKeZoXHJiPkIH5GNPEwOfBbqjE5p6A/oSuGMedfVKo/gZMKiDRrO0Qk/RZDOYoSPysaX5XMyelxvcPEVdBmXCQm4gvg46gyWE/4UN/f9TBeSZZTN4oPUgUEZAE9W1/AKu6j8jHf8WlgAAAABJRU5ErkJggg=="); }
        .register-plans .pricing-selection .plan-box.selected .price, .register-plans .pricing-selection .plan-box.selected .price small {
          color: #FFF; }
    .register-plans .pricing-selection .plan-box-features {
      padding-left: 1.5rem;
      margin-bottom: 0;
      list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAMCAYAAAC9QufkAAAAAXNSR0IArs4c6QAAAQZJREFUKBVjZCAF/P/PyHAqZCoDA6M9AxuLNyPRev83MDGcvDKHgeF/IlgPI+M1JqI0o2sEafrPcACi+UyoHMPjUE6sBmHTyMg4h8F8dQ4Tw+kQT4a//24xPPt/lOFqqBCKAWCNl+fCnQqSBGk0W50GpP8zMfxlmAN0AjvD//+GDF/+74UbANfIkIAwkHE2TCPYHIaTIXFAxnygZogXGBkvMLAyujH8+tcFFE8AKYIAoEbz1ekgG+EiYAamAZ+AhvHBFAHpWQzmazKQNYLkEFF1IjSWgfH/ArgLEDqxakTVDOJhGoBTI8JsZNap4BiGEyF/gGExA+gKhMuQ1eBln08QwCsPlQQAdDJoBt7YsisAAAAASUVORK5CYII="); }

.register-page {
  display: flex;
  height: 100vh; }
  .register-page.view {
    padding: 0; }
  .register-page > .left {
    flex: 1 0 28rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5rem 3rem;
    background-color: #fafafa;
    background-image: url("../../../assets/images/shape-dark.svg");
    min-height: 100vh;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff; }
  .register-page > .right {
    flex: 1 0 70%;
    padding: 1.5rem 4rem;
    overflow-y: auto; }
  .register-page .testimonial .icon-quote {
    display: block;
    margin-bottom: 1rem;
    color: #626f77; }
  .register-page .testimonial .text {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.6; }
  .register-page .testimonial .author {
    margin-top: 2rem; }
    .register-page .testimonial .author .name {
      margin-left: .8rem;
      font-weight: 500; }
  .register-page .bottom {
    text-align: center;
    font-size: 1.1rem; }
  .register-page .logos {
    display: grid;
    grid-template-columns: 50% 50%; }
    .register-page .logos img {
      margin-bottom: 1.5rem;
      justify-self: center; }
  .register-page form {
    max-width: 80rem;
    padding: 0 3rem;
    margin: 0 auto; }
    .register-page form .logo {
      margin-bottom: 2rem;
      text-align: center; }
    .register-page form .input-group svg {
      position: relative;
      top: auto;
      left: auto; }
    .register-page form .input-group .input-group-append {
      margin-left: -2px; }
    .register-page form .container-check .checkmark {
      top: 0; }
    .register-page form .form-text {
      font-size: .9rem; }
  .register-page .scroll {
    margin-top: 1rem;
    padding-right: 1rem;
    min-height: 10rem; }
    .register-page .scroll::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background-color: #fafafa; }
    .register-page .scroll::-webkit-scrollbar {
      width: 12px;
      background-color: #ededed; }
    .register-page .scroll::-webkit-scrollbar-thumb {
      background-color: #ededed; }
  @media screen and (max-width: 1460px) {
    .register-page form {
      padding: 0 2rem; } }
  @media screen and (max-width: 1300px) {
    .register-page > .right {
      padding: 1.5rem .2rem; } }
  @media screen and (max-width: 1180px) {
    .register-page {
      flex-direction: column;
      height: auto; }
      .register-page > .left {
        order: 1;
        justify-content: flex-start;
        min-height: auto;
        padding-top: 2rem;
        padding-bottom: 2rem; }
      .register-page > .right {
        order: 0; }
      .register-page .testimonial {
        margin-bottom: 1rem; } }
  @media screen and (max-width: 800px) {
    .register-page form .check.ml-5 {
      margin-left: 1.5rem !important; } }
  @media screen and (max-width: 760px) {
    .register-page form .check,
    .register-page form .check.ml-5 {
      display: block !important;
      margin-left: 0 !important; } }

.pricing-plan {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem; }
  .pricing-plan-box {
    position: relative;
    padding: 2rem;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(60, 64, 76, 0.02), rgba(149, 157, 165, 0.4) 0px 8px 24px; }
    .pricing-plan-box:hover {
      border: 2px solid rgba(218, 220, 226, 0.5);
      box-shadow: none;
      cursor: pointer; }
    .pricing-plan-box.selected {
      border: 2px solid #00943E !important;
      background-color: rgba(0, 148, 62, 0.1);
      box-shadow: none; }
    .pricing-plan-box .recommended-badge {
      position: absolute;
      top: -1rem;
      left: 50%;
      transform: translateX(-50%);
      padding: .15rem .3rem;
      background: #fff;
      border: 2px solid #FCB036;
      border-radius: .3rem; }
    .pricing-plan-box .recommended-text {
      display: flex;
      align-items: center;
      gap: .25rem;
      font-weight: 800;
      white-space: nowrap;
      background: #ff0000;
      background: linear-gradient(to top, #ff0000, #fdcf58);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
  .pricing-plan-price {
    min-height: 5rem;
    margin: 0 -2rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center; }
    .pricing-plan-price .number {
      font-size: 2rem;
      font-weight: 600; }
    .pricing-plan-price .period {
      margin-left: .3rem; }
    .pricing-plan-price .old-price {
      text-decoration: line-through;
      text-decoration-color: #696c77;
      margin-right: .3rem;
      font-size: 1rem;
      font-weight: 600;
      opacity: .75; }
    .pricing-plan-price .notes {
      color: #0d6efd; }
  .pricing-plan-details {
    margin-top: 1rem; }
  .pricing-plan-footer {
    margin-top: 1.5rem; }
    .pricing-plan-footer .btn {
      display: block;
      padding: .5rem 1rem;
      font-size: 1.2rem; }
  .pricing-plan .benefit-list {
    margin: 0;
    padding-left: .5rem;
    text-align: left; }
    .pricing-plan .benefit-list li {
      margin-bottom: .5rem;
      color: #3c404c;
      list-style: none;
      display: flex;
      align-items: center; }
      .pricing-plan .benefit-list li::before {
        flex: 0 0 auto;
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='nonzero' fill='%2300C853'%3E%3Cpath d='m16.637 7.663-.93-.957a.2.2 0 0 0-.148-.064.192.192 0 0 0-.148.064l-6.452 6.5-2.348-2.349a.209.209 0 0 0-.148-.063.209.209 0 0 0-.149.063l-.94.942a.21.21 0 0 0 0 .301l2.96 2.962c.191.19.424.301.62.301.28 0 .523-.206.613-.29h.005l7.07-7.109c.075-.09.075-.222-.005-.301Z'/%3E%3Cpath d='M11 1.48c2.544 0 4.934.99 6.732 2.788A9.456 9.456 0 0 1 20.52 11a9.456 9.456 0 0 1-2.787 6.732A9.456 9.456 0 0 1 11 20.52a9.456 9.456 0 0 1-6.732-2.787A9.456 9.456 0 0 1 1.48 11c0-2.544.989-4.934 2.787-6.732A9.456 9.456 0 0 1 11 1.48ZM11 0C4.924 0 0 4.924 0 11s4.924 11 11 11 11-4.924 11-11S17.076 0 11 0Z'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-left: -.6rem;
        margin-right: .6rem; }
      .pricing-plan .benefit-list li:last-child {
        margin-bottom: 0; }
    .pricing-plan .benefit-list.smaller {
      padding-left: .5rem; }
      .pricing-plan .benefit-list.smaller li {
        font-size: 1rem; }
    .pricing-plan .benefit-list.blue li::before {
      background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='nonzero' fill='%233D5FC0'%3E%3Cpath d='m16.637 7.663-.93-.957a.2.2 0 0 0-.148-.064.192.192 0 0 0-.148.064l-6.452 6.5-2.348-2.349a.209.209 0 0 0-.148-.063.209.209 0 0 0-.149.063l-.94.942a.21.21 0 0 0 0 .301l2.96 2.962c.191.19.424.301.62.301.28 0 .523-.206.613-.29h.005l7.07-7.109c.075-.09.075-.222-.005-.301Z'/%3E%3Cpath d='M11 1.48c2.544 0 4.934.99 6.732 2.788A9.456 9.456 0 0 1 20.52 11a9.456 9.456 0 0 1-2.787 6.732A9.456 9.456 0 0 1 11 20.52a9.456 9.456 0 0 1-6.732-2.787A9.456 9.456 0 0 1 1.48 11c0-2.544.989-4.934 2.787-6.732A9.456 9.456 0 0 1 11 1.48ZM11 0C4.924 0 0 4.924 0 11s4.924 11 11 11 11-4.924 11-11S17.076 0 11 0Z'/%3E%3C/g%3E%3C/svg%3E"); }
  .pricing-plan-box.recommended {
    border: 2px solid #FCB036; }
    .pricing-plan-box.recommended .pricing-plan-price {
      padding-bottom: 0; }
    .pricing-plan-box.recommended .notes {
      color: #0d6efd; }
