.view-sku-analysis .card {
  margin-bottom: 2rem; }

.sku-analysis-data {
  margin-top: 1rem; }
  .sku-analysis-data-item {
    padding: .3rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .sku-analysis-data-item:last-child {
      border-bottom: 0; }
    .sku-analysis-data-item.red {
      color: #FF7158; }
    .sku-analysis-data-item.net {
      padding-left: .5rem;
      padding-right: .5rem;
      font-size: 1rem;
      font-weight: 600;
      background-color: rgba(61, 95, 192, 0.2);
      color: #3D5FC0;
      margin-bottom: 2rem;
      border-radius: .3rem; }
      .sku-analysis-data-item.net > span:first-child {
        flex: 0 0 auto; }
    .sku-analysis-data-item > span:first-child {
      flex: 0 0 21rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: .5rem; }
      .sku-analysis-data-item > span:first-child span {
        white-space: nowrap; }
    .sku-analysis-data-item .input-group {
      max-width: 5.3rem; }

.sku-chart-empty {
  background-color: #f9fafa;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 480px; }

.last-searches {
  display: flex;
  gap: .5rem;
  flex-wrap: wrap;
  margin-top: .5rem; }
  .last-searches-item {
    padding: .25rem .5rem;
    border-radius: 12px;
    white-space: nowrap;
    font-size: .85rem;
    line-height: 1;
    color: #72879e;
    background-color: rgba(144, 161, 179, 0.2); }
    .last-searches-item span[role="button"] {
      margin-left: .3rem;
      color: #FF7158; }
