.link-account-step > li {
  display: flex;
  align-items: baseline;
  margin-bottom: 1.5rem; }
  .link-account-step > li .step {
    display: block;
    margin-right: 1rem;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-weight: 600;
    border-radius: 50%;
    color: #fff;
    background-color: #3D5FC0; }
  .link-account-step > li .text {
    position: relative; }

.popover-screen {
  width: 400px !important; }

.popover-help-bubble {
  width: 30rem !important;
  font-size: 1rem;
  box-shadow: none;
  border-radius: 2rem;
  background-color: #F2F2F2;
  border: 1px solid #F7F7F7; }
  .popover-help-bubble .popover-body {
    color: #585F55; }
  .popover-help-bubble.bs-popover-right:before {
    border-right-color: #F7F7F7; }
  .popover-help-bubble.bs-popover-right:after {
    border-right-color: #F2F2F2; }

.form-link-account .form-group, .form-link-account .btn {
  max-width: 27rem; }

.form-link-account .btn {
  margin-top: 2rem; }

.form-link-account #seller-id-input {
  position: relative; }
  .form-link-account #seller-id-input .popover-help-bubble {
    top: 50%;
    left: 50%;
    transform: translate(47%, -18%); }

#arrow-credentials {
  position: absolute;
  left: -10rem;
  transform: rotate(45deg); }
