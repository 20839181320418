.table-income-statement > tbody > tr > td + td {
  text-align: right; }

.table-income-statement > tbody > tr > td .title {
  margin: 0;
  font-size: 18px;
  font-weight: 600; }

.table-income-statement > tbody > tr.row-credit > td {
  background-color: rgba(0, 200, 83, 0.02) !important;
  color: #0f461b;
  color-adjust: exact !important;
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important; }

.table-income-statement > tbody > tr.row-cost > td {
  background-color: rgba(249, 30, 30, 0.02) !important;
  color: #210808;
  color-adjust: exact !important;
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important; }

.table-income-statement .total {
  font-weight: 700;
  font-size: 22px; }

.income-statement-print > div table td {
  line-height: 1 !important;
  padding: 0.3rem !important; }
