.reports-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem; }

.report-item {
  position: relative;
  text-align: center;
  cursor: pointer; }
  .report-item:first-child {
    margin-left: 0; }
  .report-item .icon-report {
    width: 5rem;
    margin: 0 auto;
    position: relative; }
    .report-item .icon-report .icon-accounting {
      width: 32px;
      height: 32px; }
  .report-item .title {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    margin-top: .3rem;
    margin-bottom: .5rem; }
    .report-item .title:hover {
      text-decoration: underline; }
  .report-item .icon-delete {
    position: absolute;
    top: -.3rem;
    left: 3.3rem;
    background: #fff;
    border-radius: 50%;
    border: 2px solid #fff;
    cursor: pointer; }
  .report-item .icon-edit {
    display: block;
    margin: 0 auto;
    cursor: pointer; }

.report-pagination-nav {
  display: flex;
  align-items: center;
  gap: .5rem;
  font-size: .9rem; }
