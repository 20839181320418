.view .view-dashboard .stats .card-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #eceff1; }
  .view .view-dashboard .stats .card-body svg {
    transform: translate(0, -2px); }

.view .view-dashboard .stats .card + .card .card-body:last-child {
  border: 0; }

.nav-pills-light .nav-link {
  color: #fff;
  font-weight: 600;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .nav-pills-light .nav-link.active {
    color: #00943E;
    background-color: #fff; }

.faq-topic-draggable {
  display: flex;
  align-items: center;
  padding: .5rem; }
  .faq-topic-draggable.dragging {
    opacity: 0.3; }
  .faq-topic-draggable.target-active {
    padding: .5rem;
    background: #f5f5f5;
    border-radius: 3px; }
  .faq-topic-draggable .icon-drag {
    margin-right: 1rem;
    cursor: move; }
  .faq-topic-draggable .form-group {
    flex: 1 0 auto;
    margin-bottom: 0; }

.faq-topic-add {
  margin-left: 25px;
  margin-right: -3px;
  margin-top: 6px; }
