.metric-item {
  text-align: center;
  position: relative;
  padding: 1rem;
  border: 1px solid #e8eaef;
  border-radius: .5rem; }
  .metric-item .value,
  .metric-item .title {
    display: block; }
  .metric-item .value {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1;
    margin-top: .3rem; }
  .metric-item .title {
    font-weight: 500;
    color: #848d96; }
  .metric-item .help {
    margin-left: .2rem;
    color: #929eaa; }
  .metric-item.blue .value {
    color: #3D5FC0; }
  .metric-item.green .value {
    color: #00943E; }
