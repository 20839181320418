.condition-form ol {
  padding-left: 10px; }
  .condition-form ol li {
    padding: 10px; }

.pt-0 {
  padding-top: 0; }

.pr-10 {
  padding-right: 10px; }

.pull-right {
  float: right; }

.edit-mode.condition-note.card {
  background-color: #dddddd;
  border: 1px dashed #929292; }

.edit-mode.condition-note .icon-btn {
  display: none; }

.error input,
.error textarea,
.error select {
  border: 1px solid red; }

.condition-note.card {
  border: 1px dashed #cfcfd0;
  box-shadow: none; }
  .condition-note.card:hover {
    cursor: move; }
  .condition-note.card .card-body {
    padding: 10px; }
  .condition-note.card .icon-btn {
    cursor: pointer; }
