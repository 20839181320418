.interval-dropdown {
  display: flex;
  align-items: center; }
  .interval-dropdown .custom-range {
    margin-left: 1rem; }
    .interval-dropdown .custom-range .form-control[readonly] {
      background: #fff; }
  .interval-dropdown a {
    cursor: pointer; }
  .interval-dropdown .dropdown-toggle {
    font-size: .9rem;
    font-weight: 500;
    color: inherit; }
  .interval-dropdown .dropdown-item.active {
    color: #3D5FC0;
    background: transparent; }
  .interval-dropdown .dropdown-item:focus {
    outline: 0; }
