/*********************
BREAKPOINTS
*********************/
@media (min-width: 70em) {
  .modal-createBatch {
    max-width: 800px !important; } }

.modal-createBatch .modal-content .modal-header .modal-title span {
  display: block;
  font-size: 1rem;
  font-weight: normal; }

.modal-createBatch-msku-preview {
  padding: .1rem .5rem;
  background-color: #e3e6e9;
  font-weight: 500; }

.createBatch-tooltip-contaiter {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: flex-end; }
