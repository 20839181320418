.main-form {
  min-height: 100vh;
  position: relative; }
  .main-form.register .button {
    margin-top: 30px; }
  .main-form.register .new-account {
    margin-top: 20px;
    margin-bottom: 0px;
    font-size: 18px; }
    .main-form.register .new-account:hover {
      text-decoration: underline; }
  .main-form.forgot-password .new-account {
    border-top: solid 1px #d8d8d8;
    padding-top: 30px; }
  @media (max-width: 767px) {
    .main-form .container {
      max-width: 100%;
      padding: 0px; } }
  @media (max-width: 1024px) {
    .main-form .col-6 {
      max-width: 70%;
      width: 70%;
      margin-left: 15%;
      flex: 0 0 70%; } }
  @media (max-width: 767px) {
    .main-form .col-6 {
      max-width: 100%;
      width: 100%;
      flex: 0 0 100%;
      margin: 0px; } }
  @media (max-width: 767px) {
    .main-form .col-10 {
      max-width: 100%;
      width: 100%;
      flex: 0 0 100%;
      margin: 0px; } }
  .main-form .f-card {
    background: #fff;
    padding: 2rem 3rem;
    margin-top: 30px; }
    @media (max-width: 767px) {
      .main-form .f-card {
        box-shadow: none;
        border: none;
        border-radius: 0px;
        margin-top: 0px; } }
    .main-form .f-card .title {
      color: #1c384a;
      font-size: 24px;
      line-height: 38px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase; }
    .main-form .f-card .subtitle {
      color: #1c384a;
      font-size: 14px;
      text-align: center;
      margin: 10px 0 25px; }
  .main-form .logo {
    text-align: center;
    width: 100%; }
  .main-form .forgot {
    color: #021c3f;
    font-size: 12px;
    margin-top: 10px;
    border-bottom: solid 1px #d8d8d8;
    padding-bottom: 20px; }
    .main-form .forgot a {
      color: #021c3f;
      text-decoration: underline; }
  .main-form .new-account {
    font-size: 14px;
    margin: 30px 0 20px;
    display: block;
    text-align: center; }
    .main-form .new-account a {
      color: #1c384a; }
  .main-form .copyright {
    margin-top: 2em; }

.f-container {
  margin-bottom: 20px; }
  .f-container label {
    color: #021c3f;
    font-size: 14px !important; }
  .f-container svg {
    color: #9b9b9b;
    font-size: 20px;
    position: absolute;
    top: 12px;
    left: 17px; }
  .f-container .f-text {
    color: #9b9b9b;
    font-size: 12px;
    line-height: 14px;
    margin-top: 10px; }
  .f-container .f-row {
    position: relative; }
    .f-container .f-row input {
      box-shadow: none;
      margin: 0px;
      border: 1px solid #d6dbde;
      border-radius: 4px;
      width: 100%;
      height: 43px;
      padding: 0 20px 0 48px;
      font-size: 18px; }
      .f-container .f-row input::placeholder {
        color: #d3d3d3; }
      .f-container .f-row input:focus {
        outline: none; }
        .f-container .f-row input:focus, .f-container .f-row input:focus + .input-group-addon {
          border: solid 1px #00943E; }
        .f-container .f-row input:focus + .input-group-append {
          border-left: 0; }
        .f-container .f-row input:focus + svg {
          color: #00943E; }
    .f-container .f-row .input-group:focus-within + svg {
      color: #00943E; }
    .f-container .f-row select {
      width: 100%;
      height: 58px;
      border: 1px solid #adacac;
      font-size: 18px;
      background-color: #fff;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding: 0 40px 0 18px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGQ9Ik03LjQxIDcuODRMMTIgMTIuNDJsNC41OS00LjU4TDE4IDkuMjVsLTYgNi02LTZ6Ii8+CiAgICA8cGF0aCBkPSJNMC0uNzVoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz4KPC9zdmc+Cg==");
      background-repeat: no-repeat;
      background-position: 98% center; }
    .f-container .f-row .input-group select {
      width: auto;
      padding: .7rem 1.5rem .7rem 1rem; }
    .f-container .f-row .input-group .Select .Select-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-color: #d6dbde; }
    .f-container .f-row .input-group .Select input {
      padding: 0;
      border: 0; }
    .f-container .f-row .input-group .Select .Select-input, .f-container .f-row .input-group .Select .Select-placeholder, .f-container .f-row .input-group .Select .Select-value {
      height: 41px;
      line-height: 41px; }
    .f-container .f-row .input-group .input-group-prepend + input {
      padding-left: 1rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.f-row-error {
  margin-top: .5rem; }
  .f-row-error .alert {
    padding: .25rem .5rem;
    margin-bottom: 0; }

.inline-row {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start; }
  @media (max-width: 1024px) {
    .inline-row {
      flex-direction: column; }
      .inline-row .f-container {
        width: 100% !important; } }
  .inline-row.cards-n {
    justify-content: flex-start; }
  .inline-row .f-container {
    width: calc(50% - 12px); }
    .inline-row .f-container.card-n {
      min-width: calc(50% - 12px);
      margin-right: 24px; }
    .inline-row .f-container.date {
      max-width: 90px;
      margin-right: 20px; }
      .inline-row .f-container.date input {
        padding: 0px;
        text-align: center;
        max-width: 90px; }
    .inline-row .f-container.full-width {
      width: 100%; }

.under-register-title {
  font-size: 36px;
  font-weight: bold;
  line-height: 43px;
  text-align: center;
  color: #fff;
  margin: 120px 0 84px;
  position: relative; }
  @media (max-width: 992px) {
    .under-register-title {
      font-size: 32px; } }
  .under-register-title:after {
    position: absolute;
    content: "";
    width: 30px;
    height: 4px;
    border-radius: 2px;
    left: 0px;
    right: 0px;
    margin: auto;
    background: #ffff;
    bottom: -30px; }

.under-register-img img {
  width: 100%;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.32); }

.alert-block {
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: #fff;
  padding: 40px 50px;
  position: relative;
  margin-top: 40px; }
  .alert-block .alert-title {
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    padding-bottom: 15px; }
  .alert-block .alert-text {
    line-height: 26px;
    font-size: 18px; }
  .alert-block .alert-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer; }

@media (max-width: 767px) {
  .under-register-box {
    display: none; } }

.view-forgot-password.view {
  padding-top: 6rem; }

.view-forgot-password .f-card {
  margin-top: .8rem;
  padding-top: 0; }
