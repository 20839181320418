.site-head {
  height: auto;
  z-index: 99;
  position: relative;
  padding-top: 0;
  background: #fff;
  padding-left: 15px;
  padding-right: 0;
  border-bottom: 1px solid #e8f5e9; }
  .site-head .right-elems .wrap {
    padding: 7px 12px;
    cursor: pointer;
    z-index: 2;
    line-height: 1; }
    .site-head .right-elems .wrap .dropdown-item:active {
      background: #f5f5f5;
      color: #212121; }
    .site-head .right-elems .wrap a {
      color: #272A39; }
      .site-head .right-elems .wrap a:hover, .site-head .right-elems .wrap a:focus {
        color: #00943E; }
    .site-head .right-elems .wrap .icon {
      width: 16px;
      height: 16px;
      background-color: #272A39; }
      .site-head .right-elems .wrap .icon:hover {
        background-color: #00943E; }
    .site-head .right-elems .wrap .text {
      font-weight: 500;
      font-size: .9rem;
      white-space: nowrap; }
  .site-head .right-elems .profile {
    padding: 0; }
    .site-head .right-elems .profile img {
      width: 36px;
      height: 36px;
      border-radius: 100%;
      border: 2px solid #c8e6c9; }
  .site-head .right-elems .notify .badge {
    font-size: 65%;
    position: absolute;
    top: -4px;
    right: -4px; }
  .site-head .site-search {
    position: relative; }
    .site-head .site-search input {
      font-size: 14px;
      border: none;
      border-radius: 1px;
      padding-left: 3.5rem;
      transition: .2s ease-in;
      padding-top: .9rem;
      padding-bottom: .9rem; }
    .site-head .site-search > svg {
      position: absolute;
      top: .8rem;
      left: 1rem; }
  .site-head .app-searchbar {
    margin-right: 1.5rem;
    width: 50%; }
    .site-head .app-searchbar .input-group .input-group-text {
      background-color: #fff;
      border: 0; }
    .site-head .app-searchbar .input-group input.form-control {
      border: 0;
      padding-left: 0;
      line-height: 1; }
      .site-head .app-searchbar .input-group input.form-control:read-only {
        background-color: #fff; }

#HW_badge_cont {
  visibility: hidden !important;
  position: absolute !important;
  top: 0;
  left: 0; }

.color-mode-switch .form-group {
  margin-bottom: 0; }

.color-mode-switch .switch-checkbox i {
  min-height: 22px; }
  .color-mode-switch .switch-checkbox i:after {
    font-size: 2rem;
    min-width: auto;
    left: 8px; }

.color-mode-switch .switch-checkbox input:checked + i:after {
  right: 10px;
  transform: rotate(20deg);
  font-size: 1.5rem; }
