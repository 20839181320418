.table-profit-loss {
  table-layout: fixed;
  font-size: .9rem;
  border-color: #adb2b7 !important; }
  .table-profit-loss.table-hover tbody tr:hover {
    background-color: #efefef; }
  .table-profit-loss thead {
    background-color: #fff !important; }
    .table-profit-loss thead th {
      padding-left: .3rem !important;
      padding-right: .3rem !important;
      text-align: center;
      font-weight: 600;
      border-color: #adb2b7 !important;
      border-bottom-width: 2px !important;
      background-color: #d7dade; }
      .table-profit-loss thead th:first-child {
        width: 15%;
        border-top: 1px solid #adb2b7;
        background-color: #fff; }
  .table-profit-loss thead th:nth-child(4), .table-profit-loss thead th:nth-child(7), .table-profit-loss thead th:nth-child(10), .table-profit-loss tbody td:nth-child(4), .table-profit-loss tbody td:nth-child(7), .table-profit-loss tbody td:nth-child(10), .table-profit-loss tfoot th:nth-child(4), .table-profit-loss tfoot th:nth-child(7), .table-profit-loss tfoot th:nth-child(10) {
    border-right-width: 2px;
    border-right-color: #000 !important; }
  .table-profit-loss thead th.col-future, .table-profit-loss tbody td.col-future, .table-profit-loss tfoot th.col-future {
    opacity: .5; }
  .table-profit-loss tfoot th {
    border-color: #adb2b7; }
  .table-profit-loss tr td,
  .table-profit-loss tfoot tr th {
    padding-left: .2rem !important;
    padding-right: .2rem !important;
    text-align: right; }
  .table-profit-loss tfoot tr th {
    background: #fafafa; }
    .table-profit-loss tfoot tr th:first-child {
      background: none;
      border: 0;
      text-align: left;
      padding-left: 0.6rem !important; }
  .table-profit-loss .reset-expansion-style,
  .table-profit-loss .row-expansion-style {
    padding: 0 !important; }
  .table-profit-loss tr.no-children + tr > td.reset-expansion-style {
    display: none; }
  .table-profit-loss tr.no-children > td {
    padding-left: 2.4rem !important; }
  .table-profit-loss tr.has-children {
    cursor: pointer; }
  .table-profit-loss tbody tr.expanded .icon-arrow-right {
    transform: rotate(90deg); }
  .table-profit-loss tbody tr .col-category {
    font-weight: 600; }
  .table-profit-loss tbody tr.row-credit .col-category {
    background-color: rgba(0, 148, 62, 0.05);
    color: #0f461b; }
  .table-profit-loss tbody tr.row-cost .col-category {
    background-color: rgba(249, 30, 30, 0.05);
    color: #210808; }
  .table-profit-loss tbody tr > td {
    width: 6.92%;
    border-color: #adb2b7; }
  .table-profit-loss tbody tr > td:first-child {
    width: 17%; }

.table .table-subrow {
  margin-bottom: 0;
  table-layout: fixed; }
  .table .table-subrow tbody {
    background: #fafafa; }
  .table .table-subrow tr > td {
    border: 0;
    border-right: 1px solid #adb2b7;
    border-bottom: 1px solid #adb2b7;
    width: 6%; }
    .table .table-subrow tr > td:first-child:not(.reset-expansion-style) {
      width: 14.75%;
      padding-left: 23px !important;
      text-align: left; }
    .table .table-subrow tr > td:last-child {
      border-right: 0; }
  .table .table-subrow tr:last-child > td {
    border-bottom: 0; }
  .table .table-subrow thead th:nth-child(4), .table .table-subrow thead th:nth-child(7), .table .table-subrow thead th:nth-child(10), .table .table-subrow tbody td:nth-child(4), .table .table-subrow tbody td:nth-child(7), .table .table-subrow tbody td:nth-child(10) {
    border-right-width: 2px;
    border-right-color: #000 !important; }

.pl-table-quarters {
  display: flex;
  justify-content: center;
  margin-left: 17%; }
  .pl-table-quarters > div {
    font-size: 1.3rem;
    font-weight: 600;
    flex: 1 0 20%;
    border-top: 1px solid #adb2b7;
    border-left: 2px solid #000;
    text-align: center;
    background: #d7dade;
    border-bottom: 2px solid #adb2b7; }
    .pl-table-quarters > div:first-child {
      border-left: 1px solid #adb2b7; }
    .pl-table-quarters > div:last-child {
      border-right: 1px solid #adb2b7; }
    .pl-table-quarters > div > span {
      display: block;
      margin: 0 auto;
      font-weight: 700; }

@media screen and (max-width: 1440px) {
  .table-profit-loss .sortable .order {
    display: block; }
    .table-profit-loss .sortable .order > .dropdown > .caret {
      margin-left: 0; } }
