.expense-calendar {
  margin-bottom: 3rem; }
  .expense-calendar.react-datepicker {
    border: 0;
    background-color: transparent; }
  .expense-calendar .react-datepicker__header {
    background-color: #F3F3F3;
    border-bottom-color: #D9E0D5; }
  .expense-calendar .react-datepicker__month-container {
    margin: 0 1rem;
    height: 241px;
    border: 1px solid #D9E0D5;
    border-radius: 5px;
    background-color: #fff; }
  .expense-calendar .react-datepicker__navigation {
    border-width: 1rem;
    top: 50%;
    margin-top: -14px; }
  .expense-calendar .react-datepicker__navigation--previous {
    left: -3rem; }
  .expense-calendar .react-datepicker__navigation--next {
    right: -3rem; }
  .expense-calendar .react-datepicker__day {
    cursor: auto; }
    .expense-calendar .react-datepicker__day:hover {
      background-color: transparent; }
  .expense-calendar .highlighted-expense-date, .expense-calendar .highlighted-expense-date:hover {
    background-color: #FF7158;
    color: #fff;
    border-radius: 3px;
    font-weight: 600; }
