.added-address {
  padding: 10px 0px 10px 10px;
  background: #fafafa;
  border-bottom: 1px solid #ededed; }
  .added-address:last-child {
    border-bottom: 0; }

.icon-btn-container {
  align-self: center;
  cursor: pointer; }
  .icon-btn-container .icon-btn {
    background: white;
    width: fit-content;
    padding: 8px;
    border-radius: 5px;
    float: right;
    border: 1px solid #e6e6e6; }

.form-group .pl-0 {
  padding-left: 0; }

.form-group .pr-0 {
  padding-right: 0; }

.form-control[invalid="true"] {
  border-color: #dc3545; }

.prev-added-adress {
  color: #616161; }

.title {
  font-weight: 500; }

.sub-title,
.prev-added-adress .heading {
  font-weight: 600; }

.prev-added-adress .heading {
  font-size: 12px; }

.invalid-feedback {
  color: #dc3545; }

.address-view .card-body {
  padding: 25px; }
