.order-details-modal .modal-body {
  overflow-y: scroll;
  max-height: 720px; }

.table-order-details {
  margin-bottom: 0; }
  .table-order-details th {
    font-weight: 500;
    color: #90a1b3; }
    .table-order-details th strong {
      color: #3c404c; }

.table-order-info th, .table-order-info td {
  border: 0; }

.table-order-items th {
  border-top: 0; }
