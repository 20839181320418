.popover-template-breakdown:before, .popover-template-breakdown:after {
  display: none; }

.status-countdown {
  display: inline-flex;
  align-items: center;
  border-radius: .2rem;
  border: 1px solid #636c72;
  color: #636c72;
  font-weight: 600; }
  .status-countdown .addon {
    background-color: #fff;
    padding: .2rem .3rem; }
  .status-countdown .text {
    margin: 0 .5rem;
    line-height: 1; }
  .status-countdown .icon {
    background-color: #636c72; }
  .status-countdown.ready {
    border-color: #00943E; }
    .status-countdown.ready .addon {
      background-color: #fff; }
    .status-countdown.ready .text {
      color: #00943E; }
    .status-countdown.ready .icon {
      background-color: #00943E; }
