.batch-metric {
  display: flex;
  align-items: center; }
  .batch-metric .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    margin-right: 10px;
    background-color: #D9E0D5;
    text-align: center;
    border-radius: 50%;
    vertical-align: middle; }
  .batch-metric .value {
    display: block;
    font-size: 28px;
    font-weight: 600; }
  .batch-metric .title {
    display: block;
    font-size: 12px;
    color: #858C82; }

.batch-metric-row {
  display: flex; }
  .batch-metric-row .batch-metric {
    flex: 0 0 50%;
    max-width: 50%; }

.batch-metric-group .batch-metric-row {
  margin-bottom: 30px; }
  .batch-metric-group .batch-metric-row:last-child {
    margin-bottom: 0; }

.table-closed-batches.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff; }

.table-closed-batches.table-striped tbody tr:nth-of-type(even) {
  background-color: #FAFBF9; }

.table-closed-batches .thead-default th {
  color: #3A3F37;
  font-size: 13px;
  background-color: #F1F4EF;
  border-bottom: 1px solid #D9E0D5; }
  .table-closed-batches .thead-default th > a {
    color: #3A3F37; }

.table-closed-batches tbody > tr > td {
  padding: 10px 5px;
  vertical-align: middle; }
  .table-closed-batches tbody > tr > td a {
    margin: 0;
    color: #3D5FC0; }
    .table-closed-batches tbody > tr > td a:hover, .table-closed-batches tbody > tr > td a:focus {
      color: #3755ad; }
  .table-closed-batches tbody > tr > td .badge:first-child {
    margin-left: 0; }

.btn-history-tab {
  color: #3c404c;
  border-color: #E7E7F0;
  background-color: #fff;
  box-shadow: none; }
  .btn-history-tab:active {
    background-color: #00943E;
    color: #fff; }
  .btn-history-tab.active-btn {
    background-color: #00943E;
    border-color: #00943E;
    color: #fff; }

.batch-history-table {
  margin-bottom: 0; }
  .batch-history-table.checkable th:first-child, .batch-history-table.checkable td:first-child {
    max-width: 1.2rem; }
  .batch-history-table.checkable th:nth-child(2), .batch-history-table.checkable td:nth-child(2) {
    max-width: 9rem; }
  .batch-history-table td a:hover, .batch-history-table:focus {
    text-decoration: underline; }
  .batch-history-table tfoot td {
    padding-bottom: 0; }

.table-top-controls {
  gap: 3rem; }
  .table-top-controls .btn-link-group button {
    margin-right: 0; }
  .table-top-controls .batch-searchbar {
    flex: 1; }
