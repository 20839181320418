.batch-history-metrics {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 2rem; }
  .batch-history-metrics .metric-item {
    text-align: center;
    min-width: 11.8rem;
    background-color: #fff; }
    .batch-history-metrics .metric-item .title {
      font-size: .85rem; }
    .batch-history-metrics .metric-item .value {
      font-size: 1.2rem; }

@media screen and (min-width: 1350px) {
  .batch-history-metrics {
    gap: 1.5rem;
    justify-content: center; } }
