.col-align-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.feed-items-window {
  display: flex;
  flex-direction: column;
  max-height: 500px !important;
  overflow-y: auto; }

.inventory-metrics {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.8rem;
  border-radius: 3px; }

.inventory-metric-item {
  font-weight: 600;
  text-align: center; }
  .inventory-metric-item:first-child {
    margin-left: 0; }
  .inventory-metric-item .value {
    font-size: 1.3rem; }
  .inventory-metric-item .title {
    font-size: .9rem;
    display: block;
    color: #6F7585; }

.input-group-roi input {
  background: #ffffff;
  font-size: 1rem !important;
  line-height: 2 !important; }

.ebay-listing-modal .ebay-listing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem; }

.ebay-listing-modal .ebay-action-buttons button {
  margin-left: .25rem; }

.ebay-listing-modal .ebay-action-buttons .btn-danger {
  background-color: rgba(255, 113, 88, 0.1); }
  .ebay-listing-modal .ebay-action-buttons .btn-danger img {
    height: 18px; }

.ebay-listing-modal .ebay-listing-media {
  align-items: center;
  margin-bottom: 1rem; }
  .ebay-listing-modal .ebay-listing-media .media-left {
    margin-right: 1rem;
    border: 1px solid #D9E0D5; }
  .ebay-listing-modal .ebay-listing-media img {
    max-width: 4rem; }
  .ebay-listing-modal .ebay-listing-media.add .media-left:before {
    content: "";
    display: block;
    width: 4rem;
    height: 5rem;
    background-color: #D8D8D8;
    border: 1px solid #D9E0D5; }

.ebay-listing-modal .ebay-listing-pricing .pricing-group.ebay .recent-prices {
  margin-left: 1rem; }
  .ebay-listing-modal .ebay-listing-pricing .pricing-group.ebay .recent-prices span {
    font-weight: 600; }

.ebay-listing-modal .ebay-listing-pricing .pricing-group .pricing-label {
  display: block;
  margin-bottom: 0 !important;
  font-size: 1rem !important;
  font-weight: normal; }

.ebay-listing-modal .ebay-listing-pricing .pricing-group .pricing-value {
  font-size: 1.5rem;
  font-weight: 600; }

.ebay-listing-modal .ebay-listing-pricing .pricing-group .pricing-value-nett {
  font-size: 2rem;
  font-weight: 700;
  color: #01c854; }

.ebay-listing-modal .ebay-listing-pricing .pricing-table > thead > tr > th .pricing-title {
  text-align: center;
  font-size: 1.2rem; }

.ebay-listing-modal .ebay-listing-pricing .pricing-table tr > th, .ebay-listing-modal .ebay-listing-pricing .pricing-table tr > td {
  width: 50%;
  padding: .8rem 1rem !important; }
  .ebay-listing-modal .ebay-listing-pricing .pricing-table tr > th.amazon, .ebay-listing-modal .ebay-listing-pricing .pricing-table tr > td.amazon {
    background-color: rgba(255, 153, 0, 0.05);
    color: #663d00; }
  .ebay-listing-modal .ebay-listing-pricing .pricing-table tr > th.ebay, .ebay-listing-modal .ebay-listing-pricing .pricing-table tr > td.ebay {
    background-color: rgba(0, 100, 210, 0.05);
    color: #002753; }

.ebay-listing-modal .ebay-listing-pricing .pricing-table td .form-group {
  margin-bottom: 0; }

.ebay-listing-modal .ebay-listing-details {
  padding: 1.2rem 0; }
  .ebay-listing-modal .ebay-listing-details .card-header {
    font-weight: 600;
    cursor: pointer;
    background-color: #f6f7f8;
    border-color: #e8eaed; }
    .ebay-listing-modal .ebay-listing-details .card-header svg {
      position: relative;
      top: -3px; }
  .ebay-listing-modal .ebay-listing-details .form-text {
    font-size: .85rem; }

.ebay-listing-modal .card {
  box-shadow: none; }

@media screen and (min-width: 992px) {
  .ebay-listing-modal .modal-dialog {
    max-width: 1280px; } }

.btn-list-ebay {
  background-color: #0064D2;
  color: #fff;
  border-radius: 3px; }
  .btn-list-ebay:hover, .btn-list-ebay:focus {
    background-color: #0058b9;
    color: #fff; }
  .btn-list-ebay .ebay-logo {
    max-height: 1.1rem;
    margin-left: .25rem; }

.dropzone-area {
  display: flex;
  width: 100%;
  margin: 20px;
  height: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #ccc;
  border-radius: 5px;
  cursor: pointer; }

.dropzone-component {
  position: relative;
  padding: 25px;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
