@import './md-colors';
@import './themes';

// bootstrap override
// ------------------
$brand-primary:                 #3D5FC0;
$brand-success:                 #00943E;
$brand-info:                    #90a1b3;
$brand-warning:                 #FCB036;
$brand-danger:                  #FF7158;

// ----- body$
$body-bg:                       $md-white;
$body-color:                    #3c404c;
$inverse-bg:                    $md-grey-900;

// ---- grids
$grid-gutter-width-base:        20px;

// ------ Typograpy
$font-family-poppins:         'Poppins', sans-serif;
$font-size-root:                14px;
$font-size-base:                .95rem;
$border-radius:                 .15rem;
$border-radius-sm:              .1rem;
$border-radius-lg:              .24rem;
$headings-font-weight:          700;

$small-font-size:               11px;


// ------ Buttons
$btn-secondary-border:          darken($md-grey-300, 3%);

// ------- dropdowns
$dropdown-border-color:         rgba($md-black, .1);
$dropdown-link-hover-bg:        lighten($md-blue-grey-50, 2%);

// ------ Links
$link-hover-decoration:         none;

// ------ states
$state-success-text:            $md-green-900;
$state-success-bg:              $md-green-a100;

$state-info-text:               $md-blue-900;
$state-info-bg:                 $md-light-blue-a100;

$state-warning-text:            $md-orange-900;
$state-warning-bg:              $md-orange-100;

$state-danger-text:             $md-red-900;
$state-danger-bg:               $md-red-100;

// ------- breadcrumbs
$breadcrumb-bg:                 $md-grey-100;

// ------- tags
$tag-font-weight:               normal;
$tag-font-size:                 68%;
$tag-padding-x:                 .3em;
$tag-padding-y:                 .32em;

// -------- badge
$badge-font-size:               65%;

// -------- pagination
$pagination-padding-x:                .95rem;
$pagination-padding-y:                .4rem;
$pagination-padding-x-sm:             .75rem;
$pagination-padding-y-sm:             .26rem;
$pagination-padding-x-lg:             1.5rem;
$pagination-padding-y-lg:             .7rem;
$pagination-color:                    $brand-primary;
$pagination-border-width:              1px;
$pagination-border-color:              transparent;
$pagination-active-color:              $body-color;
$pagination-active-bg:                 #fff;
$pagination-active-border:             #90a1b3;

// ------- Forms
$input-padding-x:                .8rem;
$input-padding-y:                .65rem;
$input-line-height:              1.25;

$input-border-focus:             $md-blue-a100;
$input-group-addon-bg:           $md-blue-grey-50;
$input-group-addon-border-color: $md-blue-grey-100;
$input-border-color:           #E7E7F0;
$input-bg:                      #fff;
$input-border-radius:            4px;
$input-padding-x:                .8rem;
$input-padding-y:                .5rem;
$input-bg-disabled:              #f7f8f8;
$input-height:                   33px;
$input-color-placeholder:        rgba(#272A39, .3);
$input-group-addon-bg:           #F1F0FA;
$input-border-radius-sm:         3px;

$custom-control-checked-indicator-color:      #fff;
$custom-control-checked-indicator-bg:         $brand-primary;
$custom-control-focus-indicator-box-shadow: 0 0 0 1px $body-bg, 0 0 0 3px $brand-primary;
$custom-control-active-indicator-color:      #fff;
$custom-control-active-indicator-bg:         lighten($brand-primary, 35%);
$custom-checkbox-checked-icon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-checked-indicator-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23");
$custom-checkbox-indeterminate-bg: $brand-primary;
$custom-checkbox-indeterminate-indicator-color: $custom-control-checked-indicator-color;
$custom-checkbox-indeterminate-icon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indeterminate-indicator-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23");
$custom-radio-checked-icon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-checked-indicator-color}'/%3E%3C/svg%3E"), "#", "%23");

// ------ Cards
$card-border-width:             1px;
$card-border-color:             $md-blue-grey-50;
$card-spacer-x:                 1.6rem;
$card-spacer-y:                 1rem;
$card-img-overlay-padding:      1.4rem;
$card-columns-gap:              1.3rem;
$card-cap-bg:                   $md-grey-50;

// ------- Tables
$table-cell-padding:            .8rem;
$table-sm-cell-padding:         .35rem;

$table-bg:                      transparent;

$table-inverse-bg:              $md-blue-grey-900;
$table-inverse-color:           $md-white;

$table-bg-accent:               rgba($md-black,.05);
$table-bg-hover:                rgba($md-black,.075);
$table-bg-active:               $table-bg-hover;

$table-head-bg:                 #fff;
$table-head-color:              $body-color;

$table-border-color:            $md-grey-200;


// --------- Breadcrumbs
$breadcrumb-bg:                 transparent;
$breadcrumb-padding-y:          .3rem;
$breadcrumb-padding-x:          .1rem;
$breadcrumb-item-padding:       .5rem;
$breadcrumb-divider-color:      $body-color;
$breadcrumb-active-color:       $body-color;
$breadcrumb-divider:            "→";

// ------ Z-Index
$zindex-dropdown-backdrop:  990 !default;
$zindex-navbar:            1000 !default;
$zindex-dropdown:          1000 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;
$zindex-navbar-fixed:      1030 !default;
$zindex-navbar-sticky:     1030 !default;
$zindex-modal-bg:          1040 !default;
$zindex-modal:             1050 !default;

// ------ Popover
$popover-border-color:                $input-border-color;