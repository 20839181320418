.search-modal.modal-dialog {
  max-width: 1024px; }
  .search-modal.modal-dialog .modal-content {
    height: 76.44px;
    transition: all .3s ease-out !important; }
  .search-modal.modal-dialog.search-done .modal-content {
    height: 695px; }
  .search-modal.modal-dialog .modal-body {
    padding-left: 0;
    padding-right: 1rem; }
  .search-modal.modal-dialog .search-modal-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem; }
    .search-modal.modal-dialog .search-modal-top .icon-close {
      color: #aaa;
      cursor: pointer; }
  .search-modal.modal-dialog .input-group {
    margin-left: 1rem; }
    .search-modal.modal-dialog .input-group input, .search-modal.modal-dialog .input-group .input-group-text {
      font-size: 1.5rem;
      border: 0; }
    .search-modal.modal-dialog .input-group .input {
      padding-left: 0; }
    .search-modal.modal-dialog .input-group .input-group-text {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0; }
  .search-modal.modal-dialog .search-modal-result {
    margin-top: 1.3rem;
    display: flex; }
  .search-modal.modal-dialog .articles {
    overflow-y: scroll;
    height: 600px;
    flex: 0 0 101%; }
    .search-modal.modal-dialog .articles.active {
      flex: 0 0 50%; }
    .search-modal.modal-dialog .articles .article-item {
      display: flex;
      gap: 1rem;
      align-items: center;
      padding-top: .8rem;
      padding-bottom: .8rem;
      padding-left: 1rem;
      border-bottom: 1px solid #ececec;
      cursor: pointer;
      border-left: 3px solid transparent;
      transition: all .3s ease-in-out; }
      .search-modal.modal-dialog .articles .article-item a {
        flex: 0 0 5%; }
      .search-modal.modal-dialog .articles .article-item:hover, .search-modal.modal-dialog .articles .article-item.active {
        border-left-color: #00943E;
        background-color: rgba(0, 148, 62, 0.08); }
      .search-modal.modal-dialog .articles .article-item .title {
        font-size: 1.1rem;
        font-weight: 600;
        margin-bottom: .2rem; }
      .search-modal.modal-dialog .articles .article-item p {
        margin-bottom: 0;
        font-size: .9rem;
        color: #4d4d4d; }
  .search-modal.modal-dialog .article-details {
    padding-left: 1.5rem;
    padding-top: 1rem;
    padding-right: 1rem;
    margin-right: -.95rem;
    border-top: 1px solid #ececec;
    border-left: 1px solid #ececec;
    overflow-y: scroll;
    height: 600px; }
    .search-modal.modal-dialog .article-details h1, .search-modal.modal-dialog .article-details h2, .search-modal.modal-dialog .article-details h3, .search-modal.modal-dialog .article-details h4, .search-modal.modal-dialog .article-details h5, .search-modal.modal-dialog .article-details h6 {
      font-weight: 600 !important; }
    .search-modal.modal-dialog .article-details h1 {
      font-size: 1.5rem !important; }
    .search-modal.modal-dialog .article-details h2 {
      font-size: 1.4rem !important; }
    .search-modal.modal-dialog .article-details h3 {
      font-size: 1.3rem !important; }
    .search-modal.modal-dialog .article-details h4 {
      font-size: 1.2rem !important; }
    .search-modal.modal-dialog .article-details h5 {
      font-size: 1.1rem !important; }
    .search-modal.modal-dialog .article-details h6 {
      font-size: 1rem !important; }
    .search-modal.modal-dialog .article-details img {
      max-width: 100% !important;
      height: auto !important; }
