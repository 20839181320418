.history .input-group-prepend,
.history .input-group-append {
  display: -ms-flexbox;
  display: flex; }

.history .input-group-prepend .btn,
.history .input-group-append .btn {
  position: relative;
  z-index: 2; }

.history .input-group-prepend .btn + .btn,
.history .input-group-prepend .btn + .input-group-text,
.history .input-group-prepend .input-group-text + .input-group-text,
.history .input-group-prepend .input-group-text + .btn,
.history .input-group-append .btn + .btn,
.history .input-group-append .btn + .input-group-text,
.history .input-group-append .input-group-text + .input-group-text,
.history .input-group-append .input-group-text + .btn {
  margin-left: -1px; }

.history .input-group-prepend {
  margin-right: -1px; }

.history .input-group-append {
  margin-left: -1px; }
  .history .input-group-append .btn-link {
    padding-left: .3rem;
    padding-right: .3rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-left: 0;
    border-right: 0;
    border-radius: 3px; }
    .history .input-group-append .btn-link:last-child {
      border-right: 1px solid rgba(0, 0, 0, 0.15); }

.history .input-group-text input[type="radio"],
.history .input-group-text input[type="checkbox"] {
  margin-top: 0; }

.history .input-group-lg > .form-control,
.history .input-group-lg > .input-group-prepend > .input-group-text,
.history .input-group-lg > .input-group-append > .input-group-text,
.history .input-group-lg > .input-group-prepend > .btn,
.history .input-group-lg > .input-group-append > .btn {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.history .input-group-sm > .form-control,
.history .input-group-sm > .input-group-prepend > .input-group-text,
.history .input-group-sm > .input-group-append > .input-group-text,
.history .input-group-sm > .input-group-prepend > .btn,
.history .input-group-sm > .input-group-append > .btn {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.history .input-group > .input-group-prepend > .btn,
.history .input-group > .input-group-prepend > .input-group-text,
.history .input-group > .input-group-append:not(:last-child) > .btn,
.history .input-group > .input-group-append:not(:last-child) > .input-group-text,
.history .input-group
> .input-group-append:last-child
> .btn:not(:last-child):not(.dropdown-toggle),
.history .input-group
> .input-group-append:last-child
> .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.history .input-group > .input-group-append > .btn,
.history .input-group > .input-group-append > .input-group-text,
.history .input-group > .input-group-prepend:not(:first-child) > .btn,
.history .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.history .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.history .input-group
> .input-group-prepend:first-child
> .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }
