.guide .video-container {
  padding: 2rem;
  background-color: #212121;
  text-align: center; }

.guide .complete {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 481px; }

.guide .back-to-top {
  margin-left: .5rem;
  font-weight: 600;
  font-size: 1rem; }
