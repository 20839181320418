.site-nav {
  border-right: 1px solid #eeeeee;
  width: 13rem;
  position: fixed;
  z-index: 1030;
  top: 0;
  bottom: 0;
  transition: 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.05);
  background: #ffffff;
  transform: translate(-100%, 0); }
  .site-nav.mini {
    transform: translate(0, 0); }
  @media (min-width: 992px) {
    .site-nav {
      transform: translate(0, 0); }
      .site-nav.mini {
        transform: translate(-95%, 0); }
        .site-nav.mini:hover {
          transform: translate(0, 0); } }
  .site-nav .nav-list-container {
    height: calc(100% - 7em); }
  .site-nav .nav-head {
    text-align: center;
    position: relative;
    height: inherit;
    padding: 10px 20px; }
    .site-nav .nav-head .trial-badge {
      display: block;
      background-color: #fde6e6;
      color: red;
      margin-top: 5px;
      padding: 5px 10px;
      font-weight: normal; }
  .site-nav .nav-head .toggle-dot {
    width: 11px;
    height: 11px;
    border: 2px solid #FF9900;
    border-radius: 50%;
    position: absolute;
    top: .8rem;
    right: .8rem;
    cursor: pointer; }
    .site-nav .nav-head .toggle-dot.active {
      background: #FF9900; }
    .site-nav .nav-head .toggle-dot:before {
      content: '';
      position: relative;
      display: block;
      width: 400%;
      height: 400%;
      box-sizing: border-box;
      margin-left: -150%;
      margin-top: -150%;
      border-radius: 100%;
      background-color: #FF9900;
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite; }

@keyframes pulse-ring {
  0% {
    transform: scale(0.33); }
  80%, 100% {
    opacity: 0; } }
  .site-nav .nav-head p {
    margin: auto;
    font-size: 12px;
    font-weight: 300;
    max-width: 160px;
    word-break: break-all; }
  .site-nav .nav-head .heart-icon {
    color: red; }
  .site-nav .nav-head a {
    color: #212121; }
    .site-nav .nav-head a > svg {
      position: relative;
      top: 1px; }
    .site-nav .nav-head a > strong {
      display: inline-block;
      margin-left: .2rem;
      letter-spacing: 2.4px;
      text-shadow: -0.5px 0px 0 #3b3b3b, 0.5px 0px 0 #3b3b3b; }
  .site-nav .nav-list-title {
    padding: .4rem 1.6rem;
    margin: 1.4rem 0;
    font-size: 70%;
    text-transform: uppercase;
    font-weight: bold;
    position: relative; }
    .site-nav .nav-list-title:before {
      position: absolute;
      content: "";
      width: 15px;
      height: 2px;
      bottom: 0;
      background: #00943E; }
  .site-nav .nav-list li.batch-button-container {
    text-align: center;
    padding: 20px 0 10px 0; }
  .site-nav .nav-list li a {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: .6rem 1rem;
    color: #272A39;
    font-weight: 500;
    font-size: .9rem;
    position: relative; }
    .site-nav .nav-list li a > .name {
      display: inline-block;
      margin-left: .6rem;
      transform: translate(0, 1.1px); }
    .site-nav .nav-list li a > .icon-down {
      float: right;
      transition: all .15s ease-in;
      position: absolute;
      top: 12px;
      right: 10px;
      width: 12px;
      height: 12px; }
    .site-nav .nav-list li a > .badge {
      margin-left: .3rem;
      font-size: 11px;
      position: absolute;
      right: .4rem; }
    .site-nav .nav-list li a:hover {
      color: #00943E; }
      .site-nav .nav-list li a:hover .icon {
        background-color: #00943E; }
    .site-nav .nav-list li a .badge-chart.badge {
      background: #00943E; }
    .site-nav .nav-list li a svg {
      fill: #3A3F37; }
    .site-nav .nav-list li a:hover svg {
      fill: #00943E; }
  .site-nav .nav-list li > a.active {
    color: #00943E; }
    .site-nav .nav-list li > a.active > .icon-down {
      transform: translate(0, 0.4rem) rotate(90deg); }
    .site-nav .nav-list li > a.active .icon {
      background-color: #00943E; }
  .site-nav .nav-list li.selected > a {
    font-weight: 700;
    color: #00943E; }
    .site-nav .nav-list li.selected > a .icon-down {
      transform: translate(0, 0.4rem) rotate(-270deg); }
  .site-nav .nav-list li .inner-drop {
    padding-left: 1.7rem;
    background: inherit; }
  .site-nav .nav-list li .inner-drop a {
    padding: .3rem 1.2rem; }
  .site-nav .nav-list li .inner-drop a:hover {
    color: #00943E; }

.resources-alert {
  padding: 0.5rem;
  margin: 0.5rem;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 0.25rem; }
  .resources-alert .resources-header {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem; }
  .resources-alert .resources-icon {
    margin-right: 0.5rem;
    background-color: #ffc107; }
  .resources-alert .resources-title {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    color: #856404; }
  .resources-alert .resources-list {
    list-style: none;
    padding: 0;
    margin: 0; }
  .resources-alert .resources-divider {
    margin: 0.5rem 0;
    border: 0;
    border-top: 1px solid rgba(255, 193, 7, 0.2); }
  .resources-alert .resources-link {
    font-size: 12px;
    color: #af840b;
    text-decoration: none; }
  .resources-alert .resources-link:hover {
    opacity: 0.8;
    text-decoration: underline; }
