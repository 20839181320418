.milestone-modal {
  text-align: center; }
  .milestone-modal .modal-dialog {
    max-width: 570px; }
  .milestone-modal .modal-header {
    border: 0; }
  .milestone-modal .modal-body {
    padding: 0 3rem 3rem 3rem; }
  .milestone-modal .illustration {
    width: 20rem;
    margin: 0 auto 1.5rem auto; }
  .milestone-modal .title {
    font-weight: 600;
    color: #00943E; }
  .milestone-modal .subtitle {
    font-size: 1.3rem;
    color: rgba(0, 39, 64, 0.7); }
  .milestone-modal .actions {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .milestone-modal .btn-social {
    margin: 0 1rem;
    color: #fff;
    font-size: 1.2rem;
    min-width: 15rem;
    border: 0; }
    .milestone-modal .btn-social svg {
      position: relative;
      top: -1px; }
  .milestone-modal .btn-facebook {
    background-color: #3b5998; }
  .milestone-modal .btn-twitter {
    background-color: #1da1f2; }
  .milestone-modal .btn-close {
    color: rgba(0, 39, 64, 0.7);
    text-decoration: underline; }
    .milestone-modal .btn-close:hover, .milestone-modal .btn-close:focus {
      text-decoration: none; }
