.sop-fullview {
  padding: 3rem 0; }
  .sop-fullview hr {
    margin: 1rem 0; }
  .sop-fullview .title {
    margin-bottom: 1.2rem;
    font-size: 1.8rem; }
  .sop-fullview .sop-info {
    display: flex; }
    .sop-fullview .sop-info .category {
      flex: 1 0 50%; }
    .sop-fullview .sop-info .sop-tag-list {
      margin-left: 0;
      display: inline-block; }
      .sop-fullview .sop-info .sop-tag-list > li {
        margin-right: 0;
        margin-left: .6rem; }
    .sop-fullview .sop-info .dropdown-menu {
      padding-left: .5rem;
      padding-right: .5rem;
      min-width: 30rem;
      transition: none !important; }
  .sop-fullview .sop-fulltext img {
    max-width: 100%;
    height: auto; }
