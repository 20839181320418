.settings .nav-link {
  font-weight: 600; }
  .settings .nav-link:hover, .settings .nav-link:focus {
    color: #3e4d77; }
  .settings .nav-link.disabled, .settings .nav-link.disabled:hover, .settings .nav-link.disabled:focus, .settings .nav-link:disabled, .settings .nav-link:disabled:hover, .settings .nav-link:disabled:focus {
    color: #adb4b8; }

.settings .nav-pills + .tab-content {
  margin-top: 1rem;
  padding-top: 2rem;
  border-top: 1px solid #eef0f4; }
  .settings .nav-pills + .tab-content .nav-link.active:hover, .settings .nav-pills + .tab-content .nav-link.active:focus {
    background-color: #fff; }

.settings .tab-pane {
  padding: 0 .8rem; }
  .settings .tab-pane .card {
    border: 0;
    box-shadow: none; }
    .settings .tab-pane .card .card-body {
      padding: 0; }

.settings .input-group-prepend,
.settings .input-group-append {
  display: -ms-flexbox;
  display: flex; }

.settings .input-group-prepend .btn,
.settings .input-group-append .btn {
  position: relative;
  z-index: 2; }

.settings .input-group-prepend .btn + .btn,
.settings .input-group-prepend .btn + .input-group-text,
.settings .input-group-prepend .input-group-text + .input-group-text,
.settings .input-group-prepend .input-group-text + .btn,
.settings .input-group-append .btn + .btn,
.settings .input-group-append .btn + .input-group-text,
.settings .input-group-append .input-group-text + .input-group-text,
.settings .input-group-append .input-group-text + .btn {
  margin-left: -1px; }

.settings .input-group-prepend {
  margin-right: -1px; }

.settings .input-group-append {
  margin-left: -1px; }

.settings .input-group-text input[type="radio"],
.settings .input-group-text input[type="checkbox"] {
  margin-top: 0; }

.settings .input-group-lg > .form-control,
.settings .input-group-lg > .input-group-prepend > .input-group-text,
.settings .input-group-lg > .input-group-append > .input-group-text,
.settings .input-group-lg > .input-group-prepend > .btn,
.settings .input-group-lg > .input-group-append > .btn {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.settings .input-group-sm > .form-control,
.settings .input-group-sm > .input-group-prepend > .input-group-text,
.settings .input-group-sm > .input-group-append > .input-group-text,
.settings .input-group-sm > .input-group-prepend > .btn,
.settings .input-group-sm > .input-group-append > .btn {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.settings .input-group > .input-group-prepend > .btn,
.settings .input-group > .input-group-prepend > .input-group-text,
.settings .input-group > .input-group-append:not(:last-child) > .btn,
.settings .input-group > .input-group-append:not(:last-child) > .input-group-text,
.settings .input-group
> .input-group-append:last-child
> .btn:not(:last-child):not(.dropdown-toggle),
.settings .input-group
> .input-group-append:last-child
> .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.settings .input-group > .input-group-append > .btn,
.settings .input-group > .input-group-append > .input-group-text,
.settings .input-group > .input-group-prepend:not(:first-child) > .btn,
.settings .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.settings .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.settings .input-group
> .input-group-prepend:first-child
> .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.settings .printer-settings .advanced_setup_container > div,
.settings .printer-settings .advanced_setup_container label {
  margin: 0 !important; }

.settings .printer-settings .advanced_setup_container hr {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.settings .printer-settings .spinner-container {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.815);
  align-items: center;
  justify-content: center; }

.settings .printer-settings .logo {
  padding: 34px 15px; }
  .settings .printer-settings .logo img {
    cursor: pointer;
    height: 60px; }
    .settings .printer-settings .logo img.zebra, .settings .printer-settings .logo img.rollo {
      border: 1px solid;
      border-radius: 8px;
      padding: 0 15px;
      background-color: #fff; }
    .settings .printer-settings .logo img.rollo {
      padding: 15px 60px; }

.settings .printer-settings .form-group > div,
.settings .printer-settings .form-group label {
  margin: auto; }

.settings .printer-settings .toggle-dot {
  position: relative;
  left: -15px;
  width: 11px;
  height: 11px;
  border: 2px solid #FF9900;
  background-color: #00943E;
  border-radius: 50%;
  top: 1.15rem;
  right: .8rem; }

.settings .mf-ticketing .form-group > div,
.settings .mf-ticketing .form-group label {
  margin: 0; }

.settings .payment-info {
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem; }
  .settings .payment-info p {
    margin-bottom: 0; }
  .settings .payment-info img {
    margin-right: 1rem; }
  .settings .payment-info .action {
    margin-left: 3rem; }

.settings .saved-template {
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #e6e9ec;
  border-radius: 5px; }
  .settings .saved-template .inner {
    display: flex;
    justify-content: space-between; }
  .settings .saved-template .template {
    display: flex;
    flex-wrap: wrap; }
    .settings .saved-template .template .field {
      margin-right: .5rem;
      margin-bottom: .5rem;
      padding: 0.5rem 1rem;
      color: #00943E;
      background-color: rgba(0, 148, 62, 0.05);
      border: 1px solid rgba(0, 148, 62, 0.3);
      border-radius: 6px;
      text-align: center; }
  .settings .saved-template .controls {
    display: flex;
    align-self: flex-start; }
    .settings .saved-template .controls .btn {
      margin-left: .5rem; }

.cancel-reason-modal .modal-header {
  border-bottom: 0; }

.cancel-reason-modal .title {
  text-align: center;
  margin-bottom: 1.5rem; }

.cancel-reason-modal .form-group label {
  font-weight: normal;
  font-size: 1rem !important; }

.cancel-reason-modal .reason-selection {
  display: flex;
  justify-content: space-between; }
  .cancel-reason-modal .reason-selection .reason-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.5rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 1rem;
    cursor: pointer;
    text-align: center;
    border-radius: 3px;
    font-weight: 600; }
    .cancel-reason-modal .reason-selection .reason-item input {
      display: none; }
    .cancel-reason-modal .reason-selection .reason-item:hover {
      border-color: #00943E; }
    .cancel-reason-modal .reason-selection .reason-item.selected {
      background-color: #00943E;
      border-color: #00943E;
      color: #fff; }

.modal-printer-onboarding {
  max-width: 90rem; }
  .modal-printer-onboarding .nav-dot .nav-item .nav-link {
    text-decoration: none; }
  .modal-printer-onboarding .nav-dot .nav-item:after {
    width: 12rem;
    left: 7rem; }
  .modal-printer-onboarding .download-item {
    text-align: center; }
    .modal-printer-onboarding .download-item img, .modal-printer-onboarding .download-item span {
      display: block;
      margin: 0 auto; }
    .modal-printer-onboarding .download-item .btn-download {
      margin: 1rem 0;
      background-color: #002740;
      color: #fff; }
  .modal-printer-onboarding .tab-content {
    padding: 4rem 0 2rem 0;
    text-align: center; }

.credit-card {
  width: 320px;
  height: 203px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../../assets/images/card-template-others.png");
  color: #fff; }
  .credit-card.Visa {
    background-image: url("../../../assets/images/card-template-visa.png"); }
  .credit-card.Master {
    background-image: url("../../../assets/images/card-template-mc.png"); }
  .credit-card-number {
    margin-bottom: 2.5rem;
    font-size: 1.8rem; }
  .credit-card-bottom {
    display: flex;
    justify-content: space-between; }
  .credit-card-label {
    margin-bottom: .3rem;
    font-weight: normal;
    font-size: .9rem; }
  .credit-card-value {
    font-weight: 700; }
