@charset "UTF-8";
/* SOP */
.sop-filter {
  margin-bottom: 2rem; }

.sop-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1.3rem;
  border: 1px solid #e6e9ec;
  border-radius: .5rem; }
  .sop-item .sop-title {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    font-size: 1.1rem;
    font-weight: 500; }
    .sop-item .sop-title .controls {
      position: relative;
      top: -2px;
      margin-left: .5rem; }
      .sop-item .sop-title .controls .icon {
        font-size: 1.1rem;
        margin-left: .3rem;
        padding: 0;
        color: #6F7585;
        border: 0;
        line-height: 1; }
      .sop-item .sop-title .controls .icon-favorite {
        cursor: pointer; }
        .sop-item .sop-title .controls .icon-favorite.active {
          fill: #fbdc01; }
  .sop-item .title-text {
    color: #212121;
    text-decoration: underline; }
    .sop-item .title-text:hover {
      text-decoration: none; }

.sop-list .sop-item .sop-left, .sop-list-header .sop-item .sop-left {
  flex: 0 0 60%;
  gap: 1rem; }

.sop-list .sop-item .sop-right, .sop-list-header .sop-item .sop-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5rem; }
  .sop-list .sop-item .sop-right .sop-category, .sop-list-header .sop-item .sop-right .sop-category {
    width: 11rem; }
  .sop-list .sop-item .sop-right .sop-actions, .sop-list .sop-item .sop-right .sop-last-updated, .sop-list-header .sop-item .sop-right .sop-actions, .sop-list-header .sop-item .sop-right .sop-last-updated {
    flex: 0 0 auto; }
  .sop-list .sop-item .sop-right .sop-actions, .sop-list-header .sop-item .sop-right .sop-actions {
    width: 3.75rem; }
  .sop-list .sop-item .sop-right .sop-last-updated, .sop-list-header .sop-item .sop-right .sop-last-updated {
    width: 7rem; }

.sop-list-header .sop-item {
  padding-bottom: 0;
  border: 0;
  text-transform: uppercase; }
  .sop-list-header .sop-item > .sop-left, .sop-list-header .sop-item .sop-right > div {
    font-weight: 600; }

.sop-actions {
  display: flex;
  gap: .5rem; }

.sop-tag-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0; }
  .sop-tag-list > li {
    display: inline-block;
    margin-right: .6rem;
    margin-top: 0.75rem; }

.sop-tag {
  padding: 0.5em 0.8em;
  background-color: rgba(231, 231, 240, 0.25);
  color: #464A4A;
  font-weight: normal;
  border: 1px solid rgba(231, 231, 240, 0.75);
  border-radius: 3px; }

.view-content.sop {
  padding-bottom: 10rem; }

.sop-details .title {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 600; }
  .sop-details .title .btn {
    margin-left: 1rem; }

.sop-details .sop-fulltext div[style="text-align:none;"] {
  text-align: center; }

.sop-details .sop-fulltext img {
  max-width: 100%;
  height: auto; }

.sop-details .sop-info {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid #e6e9ec; }
  .sop-details .sop-info > span::after {
    content: "•";
    display: inline-block;
    padding: 0 .8rem; }
  .sop-details .sop-info > span:last-child::after {
    display: none; }
  .sop-details .sop-info .sop-tag-list {
    display: inline-block; }
    .sop-details .sop-info .sop-tag-list > li {
      margin-top: 0;
      margin-bottom: 0; }

.sop-create-edit .card-header a svg {
  position: relative;
  top: -2px; }

.sop-create-edit .react-tagsinput {
  margin-bottom: 0;
  border-radius: 3px; }

.sop-create-edit .react-tagsinput-input {
  display: inline-block;
  width: auto;
  padding-top: 3px;
  margin-bottom: 1px; }

.sop-create-edit .react-tagsinput-tag {
  padding: 0 5px; }

.sop-create-edit .rdw-editor-main {
  height: 20rem;
  padding: 0 .6rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #fff; }

.sop-create-edit .btn-cancel {
  background: #fff;
  color: #858C82;
  border-color: #E7E7F0;
  box-shadow: none; }
  .sop-create-edit .btn-cancel:hover {
    background-color: #fafafa;
    border-color: #E7E7F0;
    color: #858C82; }

.sop-create-edit .uploaded-file {
  margin-bottom: 1rem; }
  .sop-create-edit .uploaded-file .file-name {
    text-decoration: underline;
    cursor: pointer; }
    .sop-create-edit .uploaded-file .file-name:hover {
      text-decoration: none; }
  .sop-create-edit .uploaded-file .btn {
    position: relative;
    top: -2px;
    margin-left: .5rem; }
