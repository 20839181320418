.modal-template-scheduler {
  max-width: 60%; }

.modal-template-scheduler .modal-header {
  padding-bottom: 0;
  border-bottom: 0; }

.modal-template-scheduler .modal-body {
  padding: 1.5rem 2rem; }

.modal-template-scheduler .title {
  margin-bottom: 3rem;
  font-size: 1.3rem;
  text-align: center; }

.modal-template-scheduler .form-group {
  margin-bottom: 2rem; }
