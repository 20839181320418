/*********************
BREAKPOINTS
*********************/
@media (min-width: 70em) {
  .modal-mwsAuthorize {
    max-width: 50em !important; } }

.modal-mwsAuthorize .modal-content .modal-header .modal-title span {
  display: block;
  font-size: 1rem;
  font-weight: normal; }

.modal-mwsAuthorize .modal-content .modal-body form .form-group a.anchor-amazon {
  margin: 0;
  text-decoration: underline;
  cursor: pointer; }
  .modal-mwsAuthorize .modal-content .modal-body form .form-group a.anchor-amazon:hover {
    text-decoration: none; }

.modal-mwsAuthorize .modal-content .modal-body form .form-group.step-field {
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
  margin-bottom: 2em; }
  .modal-mwsAuthorize .modal-content .modal-body form .form-group.step-field:last-child {
    border: none; }

.modal-mwsAuthorize .modal-content .modal-body form .form-group:last-child {
  margin-bottom: 0; }

.modal-mwsAuthorize .modal-content .modal-body form .form-group .step-number {
  display: block;
  margin-right: 1em;
  background: #00943E;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px; }

.modal-mwsAuthorize .modal-content .modal-body form .form-group .right {
  flex: 1 1 auto; }

.modal-mwsAuthorize .modal-content .modal-body form .form-group label {
  display: block;
  font-size: 18px !important; }
