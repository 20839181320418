.view-content.view-fullwidth {
  padding-left: 0;
  padding-right: 0;
  border: 0;
  box-shadow: none; }
  .view-content.view-fullwidth .card-body {
    padding: 2rem; }

@media screen and (min-width: 768px) {
  .view-content.view-fullwidth {
    padding-left: 0;
    padding-right: 0; } }

.page-header {
  display: flex;
  margin-bottom: 40px; }
  .page-header .page-title {
    margin-bottom: 0;
    font-size: 22px;
    font-weight: 600; }
  .page-header .page-subtitle {
    padding: 4px 0 4px 15px;
    margin-left: 15px;
    border-left: 1px solid #ccc;
    line-height: 1;
    font-size: 15px;
    color: #858C82; }

.page-info {
  margin: 0;
  color: #3D5FC0;
  background-color: rgba(61, 95, 192, 0.06);
  padding: 10px 15px;
  border-radius: 5px; }
  .page-info .icon {
    vertical-align: sub;
    margin-right: 5px; }

.metrics-vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; }
  .metrics-vertical .metric-item {
    flex-basis: 6rem;
    flex-shrink: 0; }

.metrics-horizontal {
  display: flex;
  justify-content: space-between; }
