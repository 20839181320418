.upload-info p {
  margin-bottom: 0;
  font-size: 1.3rem; }

.research-product-list .top-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 .8rem;
  margin-bottom: 1.5rem; }

.table-product-research {
  table-layout: auto !important;
  font-size: .92rem; }
  .table-product-research thead th {
    font-weight: 600; }
  .table-product-research th, .table-product-research th[data-row-selection], .table-product-research td {
    padding-left: .4rem !important;
    padding-right: .4rem !important; }
  .table-product-research tr:hover {
    cursor: pointer; }
  .table-product-research tr.selected {
    background-color: #f9fafb; }
  .table-product-research tr .head-image {
    text-indent: -9999px; }
  .table-product-research td {
    vertical-align: middle; }
  .table-product-research .container-check .checkmark {
    top: 50%;
    transform: translateY(-75%); }
  .table-product-research .selection-cell-header .container-check .checkmark {
    transform: translateY(-100%); }
  .table-product-research .col-title {
    width: 10%;
    text-align: left; }
  .table-product-research .col-category {
    width: 10%; }
  .table-product-research .picture img {
    max-width: 2rem; }
  .table-product-research .product-title {
    margin-bottom: 0;
    font-size: .9rem;
    font-weight: normal; }

.dropdown-checkbox .dropdown-item {
  padding: 0; }
  .dropdown-checkbox .dropdown-item label {
    display: block;
    margin: 0 !important;
    padding: .5rem .8rem .5rem 2.2rem;
    cursor: pointer; }

.product-list-group .product-list-item {
  display: flex;
  align-items: center; }
  .product-list-group .product-list-item .picture {
    width: 1.5rem;
    margin-right: .5rem; }
  .product-list-group .product-list-item .title {
    font-weight: 500;
    font-size: .9rem;
    overflow-wrap: anywhere; }

.table-incomplete-items {
  table-layout: auto !important; }
  .table-incomplete-items tr .head-image {
    text-indent: -9999px; }
  .table-incomplete-items .col-title {
    width: 30%;
    text-align: left; }
  .table-incomplete-items .picture {
    display: inline; }
    .table-incomplete-items .picture img {
      max-width: 2rem; }
  .table-incomplete-items .product-title {
    font-size: .9rem;
    font-weight: normal; }

.research-complete-status .timer {
  border: 1px solid #eee;
  padding: .3rem .5rem;
  border-radius: 1rem;
  background-color: #fafafa; }
  .research-complete-status .timer svg {
    vertical-align: sub !important;
    fill: #9ea6ab; }
  .research-complete-status .timer .value {
    font-weight: 600; }

.research-actions {
  display: flex;
  justify-content: space-between; }
  .research-actions > div {
    display: flex;
    align-items: center; }
