.nav-pills-step .step,
.nav-pills-step .title {
  display: block;
  font-weight: 600; }

.nav-pills-step .title {
  font-size: 1.1rem; }

.nav-pills-step .nav-link {
  display: flex;
  align-items: center;
  margin-right: .8rem;
  cursor: pointer;
  background-color: #eeeff0;
  color: #8f969c !important;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .nav-pills-step .nav-link .icon-arrow {
    margin-left: 1rem;
    background-color: #C6CAC3;
    transition: all .3s ease-in-out; }
  .nav-pills-step .nav-link:hover .icon-arrow {
    transform: translateX(0.3rem); }
  .nav-pills-step .nav-link.active {
    cursor: pointer; }
    .nav-pills-step .nav-link.active .icon-arrow {
      background-color: #1842B6; }
    .nav-pills-step .nav-link.active:hover {
      color: #fff; }
  .nav-pills-step .nav-link.step-ok, .nav-pills-step .nav-link.step-ok:hover {
    color: #00943E !important;
    background-color: rgba(0, 148, 62, 0.1); }
    .nav-pills-step .nav-link.step-ok .icon-arrow, .nav-pills-step .nav-link.step-ok:hover .icon-arrow {
      background-color: #00943E; }

.tab-content-step {
  padding: 2rem;
  background-color: #fff; }

.onboarding label {
  font-size: 1rem; }
  .onboarding label.label-secondary {
    display: block;
    font-size: 1rem; }

.onboarding .Select-value-label img {
  position: relative;
  top: -.1rem; }

.onboarding .tab-pane .card {
  box-shadow: none; }

.onboarding .tab-pane .card-body {
  padding: 0; }

.printer-selection .printer-item {
  margin-right: 2rem;
  display: inline-flex; }
  .printer-selection .printer-item .printer-info {
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    padding: 1rem 1.5rem; }
    .printer-selection .printer-item .printer-info:hover {
      border-color: #00943E; }
    .printer-selection .printer-item .printer-info img {
      width: 10rem; }
  .printer-selection .printer-item:first-child {
    margin-left: 0; }
  .printer-selection .printer-item .name {
    display: block;
    font-size: 1.3rem;
    font-weight: 600; }
  .printer-selection .printer-item .printer-instruction {
    display: none;
    margin-left: 1.5rem; }
    .printer-selection .printer-item .printer-instruction ol {
      padding-left: .8rem; }
      .printer-selection .printer-item .printer-instruction ol li {
        margin-bottom: .5rem; }
  .printer-selection .printer-item .btn-printer-selection {
    display: none; }
  .printer-selection .printer-item.show {
    display: inline-flex; }
    .printer-selection .printer-item.show .printer-instruction,
    .printer-selection .printer-item.show .btn-printer-selection {
      display: block; }
  .printer-selection .printer-item.hide {
    display: none; }
    .printer-selection .printer-item.hide .printer-instruction,
    .printer-selection .printer-item.hide .btn-printer-selection {
      display: none; }
