.ebay-stat-card .metric-group {
  display: flex;
  justify-content: space-between;
  gap: 1rem; }
  .ebay-stat-card .metric-group .item {
    display: flex;
    flex-direction: column; }
    .ebay-stat-card .metric-group .item .box {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center; }
  .ebay-stat-card .metric-group .separator {
    border-left: 1px solid #D9E0D5; }

.ebay-stat-card .metric-big-box {
  flex: 1;
  text-align: center; }
  .ebay-stat-card .metric-big-box .title {
    margin-bottom: .5rem;
    color: #6F7585; }
  .ebay-stat-card .metric-big-box .box {
    border: 1px solid #e8eaef;
    background-color: #fff;
    border-radius: 3px; }
  .ebay-stat-card .metric-big-box .value-top {
    display: block;
    padding: 1rem;
    font-size: 1.8rem;
    font-weight: 600;
    color: #6F7585; }
  .ebay-stat-card .metric-big-box .value-bottom {
    display: block;
    padding: .3rem 1rem;
    font-size: 1.4rem;
    font-weight: 600;
    color: #00943E;
    border-top: 1px solid #e8eaef; }

.ebay-stat-card .metric-small-group .metric-small-box {
  margin-bottom: .5rem; }

.ebay-stat-card .metric-small-box {
  width: 5rem;
  text-align: center; }
  .ebay-stat-card .metric-small-box .title {
    color: #6F7585; }
  .ebay-stat-card .metric-small-box .value {
    display: block;
    padding: 0 .5rem;
    font-size: 1.8rem;
    font-weight: 600;
    color: #6F7585;
    border: 1px solid #D9E0D5;
    background-color: #fff;
    border-radius: 3px; }

@media screen and (max-width: 1340px) {
  .ebay-stat-card .metric-big-box {
    min-width: 10rem; } }
