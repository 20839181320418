.view .view-content.ebay-app {
  margin-top: 0; }
  .view .view-content.ebay-app > .card {
    margin-bottom: 2rem; }

.ebay-app .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem; }
  .ebay-app .header .info {
    padding: .5rem .8rem;
    background-color: rgba(0, 99, 209, 0.12);
    color: #3758B3;
    border-radius: 3px;
    font-weight: 600; }

.list-ebay .section-header {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  cursor: pointer; }
  .list-ebay .section-header .section-title {
    margin-bottom: 0; }
  .list-ebay .section-header .icon {
    transform: scale(-1);
    transition: all .3s ease-out; }
  .list-ebay .section-header.collapsed .icon {
    transform: scale(1); }

.list-ebay .section-title {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  font-weight: 600; }

.list-ebay .collapse-wrapper {
  padding: 0 1rem;
  margin-top: 1rem; }
  .list-ebay .collapse-wrapper .collapse {
    margin-bottom: 1rem; }

.list-ebay .view-content > .card {
  margin-bottom: 1.5rem; }
  .list-ebay .view-content > .card > .card-body {
    padding-top: 0;
    padding-bottom: 0; }

.list-ebay .drag-drop-upload-images .image-item {
  margin-top: 0; }

.list-ebay .input-has-dropdown .title {
  margin-bottom: 0;
  font-weight: 600; }

.list-ebay .input-has-dropdown .attribute {
  font-size: .9rem; }

.list-ebay .input-has-dropdown .product-list-group .product-list-item .picture {
  width: 2.5rem; }

.ebay-marketing-convert .content-container {
  background-color: #fff; }

.ebay-marketing-convert .marketing-main {
  max-width: 95rem;
  margin: 0 auto; }

.ebay-marketing-convert .marketing-section {
  padding-left: 4rem;
  padding-right: 4rem; }

.ebay-marketing-convert .section-top {
  display: flex;
  padding-top: 4rem;
  padding-bottom: 8rem;
  position: relative; }
  .ebay-marketing-convert .section-top:before {
    content: "";
    display: block;
    background-color: #00943E;
    background: linear-gradient(90deg, #0036c8 0%, #00c853 100%);
    width: 57%;
    height: 219%;
    position: absolute;
    top: -39rem;
    left: -3rem;
    border-radius: 2rem;
    z-index: 1;
    transform: rotate(-9deg); }
  .ebay-marketing-convert .section-top .left {
    padding-right: 5rem;
    flex: 0 1 60%;
    color: #fff;
    z-index: 2; }
    .ebay-marketing-convert .section-top .left .title {
      line-height: 1.3;
      font-weight: 700; }
    .ebay-marketing-convert .section-top .left p {
      margin: 1rem 0;
      font-size: 1.1rem;
      font-weight: 600; }
  .ebay-marketing-convert .section-top .right {
    position: relative; }
    .ebay-marketing-convert .section-top .right .title {
      position: relative;
      left: 3rem;
      font-size: 2rem; }

.ebay-marketing-convert .ebay-result {
  display: flex;
  align-items: center;
  padding: .7rem 1rem;
  border-radius: .3rem;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); }
  .ebay-marketing-convert .ebay-result:nth-of-type(1) {
    top: 3rem;
    right: 0; }
  .ebay-marketing-convert .ebay-result:nth-of-type(2) {
    top: 9rem;
    right: 5rem; }
  .ebay-marketing-convert .ebay-result:nth-of-type(3) {
    top: 15rem;
    right: -4rem; }
  .ebay-marketing-convert .ebay-result:nth-of-type(4) {
    top: 21rem;
    right: auto;
    left: -4rem; }
  .ebay-marketing-convert .ebay-result .seller-info {
    font-size: 1.2rem; }
    .ebay-marketing-convert .ebay-result .seller-info span {
      display: block; }
    .ebay-marketing-convert .ebay-result .seller-info .started {
      font-size: .85rem;
      color: #636c72; }
  .ebay-marketing-convert .ebay-result .total {
    margin-left: 1rem;
    font-size: 1.7rem;
    font-weight: 700;
    color: #00943E; }

.ebay-marketing-convert .section-bottom {
  margin-top: 2rem; }
  .ebay-marketing-convert .section-bottom .title {
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    line-height: 1.4; }

.ebay-marketing-convert .testimonial {
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: #f6f9f6;
  border-radius: 5px; }
  .ebay-marketing-convert .testimonial .picture {
    width: 5rem; }
    .ebay-marketing-convert .testimonial .picture img {
      border-radius: 50%; }
  .ebay-marketing-convert .testimonial .content {
    flex: 1 0 50%;
    margin-left: 1.5rem; }

@media screen and (max-width: 1450px) {
  .ebay-marketing-convert .section-top .right .title {
    font-size: 1.6rem; }
  .ebay-marketing-convert .ebay-result:nth-of-type(4) {
    top: 20rem;
    left: 0; } }

@media screen and (max-width: 1280px) {
  .ebay-marketing-convert .section-top:before {
    top: -40rem; }
  .ebay-marketing-convert .section-top .right .title {
    font-size: 1.4rem; }
  .ebay-marketing-convert .testimonial {
    flex-direction: column;
    font-size: .85rem; }
    .ebay-marketing-convert .testimonial .picture {
      margin-bottom: .5rem; }
    .ebay-marketing-convert .testimonial .content {
      margin: 0; } }
