.profit-report-viewer a {
  color: #34495e; }
  .profit-report-viewer a:hover, .profit-report-viewer a:focus {
    color: #00b9a1; }

.profit-report-viewer .dashboard-tab {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-right: 51px;
  padding: 0px 2px 20px 0px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px; }

.profit-report-viewer .dashboard-tab-container {
  height: 61px;
  border-bottom: 1px solid #cfd7d9;
  margin-bottom: 18px;
  padding: 24px 28px 0px;
  background-color: white; }
  .profit-report-viewer .dashboard-tab-container a.active-tab {
    color: #00b9a1; }

.profit-report-viewer .calculation-container {
  border: 1px solid #d4dadc;
  border-radius: 4px;
  display: inline-block;
  width: 200px;
  margin-right: 18px;
  color: #0e4049;
  font-weight: 500;
  background: white; }
  .profit-report-viewer .calculation-container .calculation-header {
    padding: 12px 0px 8px;
    border-radius: 0px 0px 4px 4px;
    font-size: 18px;
    line-height: 32px;
    color: #162831;
    text-align: center;
    vertical-align: middle; }
  .profit-report-viewer .calculation-container .calculation-body {
    border-top: 1px solid #d4dadc;
    text-align: center;
    padding: 7px 4px;
    border-radius: 0px 0px 4px 4px;
    color: #0e4049;
    background: #f7f8f9; }

.profit-report-viewer .panel-header-text {
  color: #747f85;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding-bottom: 14px; }

.profit-report-viewer .equals {
  display: inline-block;
  font-size: 30px;
  opacity: 0.3;
  vertical-align: top;
  line-height: 64px;
  padding: 0px 30px 0px 12px; }

.profit-report-viewer .profit {
  font-size: 36px;
  color: #21323b;
  vertical-align: middle;
  height: 60px;
  line-height: 60px;
  font-weight: 500; }

.profit-report-viewer .label-primary {
  background-color: #337ab7; }

.profit-report-viewer .label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em; }

.profit-report-viewer .panel-body h5,
.profit-report-viewer .panel-body h4 {
  font-weight: 600; }
