.welcome-modal .header {
  text-align: center;
  margin-bottom: 2rem; }
  .welcome-modal .header .title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: .5rem;
    margin-bottom: 1.2rem; }
  .welcome-modal .header .lead {
    margin-bottom: 1.5rem; }

.welcome-modal .footer {
  padding-top: 1.5rem;
  text-align: center; }

.welcome-modal .card-deck {
  margin-bottom: 1rem; }
  .welcome-modal .card-deck .card {
    border: 0;
    box-shadow: none; }
  .welcome-modal .card-deck .card-title {
    margin-bottom: .5rem;
    font-size: 1.1rem; }
  .welcome-modal .card-deck .card-img-top {
    border-radius: 5px; }
  .welcome-modal .card-deck .card-text {
    font-size: .95rem;
    color: #636c72; }
  .welcome-modal .card-deck .card-body {
    padding-left: 0;
    padding-right: 0; }

.welcome-modal .modal-body {
  padding: 2rem; }

@media screen and (min-width: 576px) {
  .welcome-modal.modal-dialog {
    max-width: 1100px; } }
