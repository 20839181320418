.metric-box-item .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem .8rem;
  margin: .1rem;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: #ccc; }

.metric-box-item .title {
  margin-bottom: 0;
  font-size: .95rem; }

.metric-box-item .help {
  line-height: 1;
  font-size: 1.2rem; }

.metric-box-item .content {
  padding: 1rem .8rem; }

.metric-box-item.color1 .header {
  background: #ebeef0;
  color: #333d43; }

.metric-box-item.color2 .header {
  background: #ecf9ec;
  color: #1f611f; }

.metric-box-item.color3 .header {
  background: #eaf3fb;
  color: #14436c; }

.metric-box-item.color4 .header {
  background: #fff6d5;
  color: #6f5700; }

.metric-mini .metric-title {
  font-size: .9rem;
  font-weight: normal;
  line-height: 1;
  color: #4f5a63; }

.metric-mini .metric-value {
  font-weight: 600; }

.metric-mini .table-metrics {
  margin: 0; }
  .metric-mini .table-metrics thead th,
  .metric-mini .table-metrics tbody td {
    padding: 0 .3rem !important;
    border: 0;
    text-align: center;
    border-right: 1px solid #ccc; }
    .metric-mini .table-metrics thead th:last-child,
    .metric-mini .table-metrics tbody td:last-child {
      border-right: 0; }
