.verify-phone .img-phone {
  height: 18rem; }

.verify-phone .title {
  font-size: 1.4rem; }

.verify-phone .sms-code-input {
  width: 537px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: inline-block;
  position: relative;
  display: flex;
  justify-content: space-around; }
  .verify-phone .sms-code-input .display {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    width: 133px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 64px;
    position: relative;
    background-color: #fff; }
    .verify-phone .sms-code-input .display:last-child {
      border-right: none; }
  .verify-phone .sms-code-input .input {
    position: absolute;
    border: none;
    font-size: 32px;
    text-align: center;
    background-color: transparent;
    outline: none; }
  .verify-phone .sms-code-input .shadows {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    box-shadow: 0 0 0 4px rgba(58, 151, 212, 0.28); }
