.template-type-group {
  display: flex;
  justify-content: center; }

.template-box {
  position: relative;
  width: 14rem;
  padding: 1.8rem 1.5rem;
  margin: 0 1rem;
  text-align: center;
  border: 1px solid #E1E3E9;
  border-radius: .3rem;
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
  cursor: pointer; }
  .template-box .template-title {
    margin-top: 2rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: #3D5FC0; }
  .template-box .template-description {
    margin-bottom: 0;
    font-size: 1rem;
    color: #999EAE; }
  .template-box .icon-help-circle {
    position: absolute;
    top: .5rem;
    right: .5rem; }
  .template-box:hover {
    box-shadow: none; }
  .template-box.selected {
    border-color: #92A8E5;
    box-shadow: 0 2px 10px 0 rgba(0, 71, 255, 0.15); }
  .template-box.disabled {
    cursor: not-allowed;
    box-shadow: none; }
    .template-box.disabled img {
      filter: grayscale(1); }
    .template-box.disabled .template-title {
      color: #9da3b5; }
